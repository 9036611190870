import React, { useState } from 'react';

import { TextField, MenuItem } from '@material-ui/core';

import CardBody from '../card-body/card-body';

function LengthConversion() {
  const [state, setState] = useState({
    lengthFrom: 'inches',
    lengthTo: 'feet',
    value: null,
    inputValue: '',
  });

  let total = 0;

  const conversions = {
    inches: {
      from: () => state.inputValue * 2.54,
      to: centimeter => centimeter / 2.54,
      name: 'Inches',
    },
    feet: {
      from: () => state.inputValue * 30.48,
      to: centimeter => centimeter / 30.48,
      name: 'Feet',
    },
    yards: {
      from: () => state.inputValue * 91.44,
      to: centimeter => centimeter / 91.44,
      name: 'Yards',
    },
    rods: {
      from: () => state.inputValue * 503,
      to: centimeter => centimeter / 503,
      name: 'Rods',
    },
    poles: {
      from: () => state.inputValue * 503,
      to: centimeter => centimeter / 503,
      name: 'Poles',
    },
    perches: {
      from: () => state.inputValue * 503,
      to: centimeter => centimeter / 503,
      name: 'Perches',
    },
    furlongs: {
      from: () => state.inputValue * 20116.8,
      to: centimeter => centimeter / 20116.8,
      name: 'Furlongs',
    },
    miles: {
      from: () => state.inputValue * 160934,
      to: centimeter => centimeter / 160934,
      name: 'Miles',
    },
    leagues: {
      from: () => state.inputValue * 555600,
      to: centimeter => centimeter / 555600,
      name: 'Leagues',
    },
    millimeters: {
      from: () => state.inputValue / 10,
      to: centimeter => centimeter * 10,
      name: 'Millimeters',
    },
    centimeters: {
      from: () => state.inputValue * 1,
      to: centimeter => centimeter / 1,
      name: 'Centimeters',
    },
    decimeters: {
      from: () => state.inputValue * 10,
      to: centimeter => centimeter / 10,
      name: 'Decimeters',
    },
    meters: {
      from: () => state.inputValue * 100,
      to: centimeter => centimeter / 100,
      name: 'Meters',
    },
    dekameters: {
      from: () => state.inputValue * 1000,
      to: centimeter => centimeter / 1000,
      name: 'Dekameters',
    },
    hectometers: {
      from: () => state.inputValue * 10000,
      to: centimeter => centimeter / 10000,
      name: 'Hectometers',
    },
    kilometers: {
      from: () => state.inputValue * 100000,
      to: centimeter => centimeter / 100000,
      name: 'Kilometers',
    },
  };

  const lengthConverter = (e, from) => {
    const lengthDir = from === 'from' ? 'lengthFrom' : 'lengthTo';
    const type = e.target.value;

    setState(s => ({
      ...s,
      [lengthDir]: type,
    }));
  };

  const handleTextChange = e => {
    const inputValue = e.currentTarget.value;
    setState(s => ({ ...s, inputValue }));
  };

  if (state.lengthFrom && state.lengthTo) {
    const centimeter = conversions[state.lengthFrom].from();
    total = conversions[state.lengthTo].to(centimeter);
    total = total.toFixed(3);
  }

  return (
    <>
      <CardBody title="Length Converter">
        <TextField
          id="filled-basic"
          label="Length"
          variant="filled"
          type="number"
          onChange={handleTextChange}
          value={state.inputValue}
          style={{ marginRight: 10 }}
        />
        <TextField
          id="length-from-types"
          select
          name="from-type"
          label="From"
          value={state.lengthFrom}
          onChange={e => lengthConverter(e, 'from')}
          variant="filled"
          style={{ background: 'white', marginRight: 10, minWidth: 110 }}
        >
          {Object.keys(conversions).map((conversion, i) => (
            <MenuItem key={i} value={conversion}>
              {conversions[conversion].name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="length-to-types"
          onChange={e => lengthConverter(e, 'to')}
          value={state.lengthTo}
          select
          name="to-type"
          label="To"
          variant="filled"
          style={{ background: 'white', minWidth: 110, marginRight: 10 }}
        >
          {Object.keys(conversions).map((conversion, i) => (
            <MenuItem key={i} value={conversion}>
              {conversions[conversion].name}
            </MenuItem>
          ))}
        </TextField>
        {total !== 0 && (
          <div id="length-output">
            {state.inputValue} {conversions[state.lengthFrom].name} = {total}{' '}
            {conversions[state.lengthTo].name}
          </div>
        )}
      </CardBody>
    </>
  );
}

export default LengthConversion;
