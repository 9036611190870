import React, { memo } from 'react';
import { Grid } from '@material-ui/core';

import Section from '../../section/section';
import Card from '../../card/card';
import ButtonWrap from '../../button-wrap/button-wrap';

import { getFeaturedImage } from '../../../utils/utils';

const fixPostType = postType => postType;

function GlossaryCollection({
  glossaryCollection = [],
  index,
  layoutType,
  sectionSettings,
  buttonComponent,
  sectionTitle,
  onClick,
  ...props
}) {
  const getImage = React.useCallback(getFeaturedImage, []);

  return (
    <Section
      index={index}
      className="section-3"
      style={{ padding: '40px 0 100px' }}
    >
      <div className="section-wrap">
        <h2>{sectionTitle}</h2>
        <Grid container spacing={3}>
          {glossaryCollection.length !== 0
            ? glossaryCollection.map(
                (
                  {
                    post_title,
                    title,
                    post_excerpt,
                    excerpt,
                    post_name,
                    slug,
                    post_type,
                    type,
                    url,
                    _embedded,
                  },
                  i,
                ) => (
                  <Card
                    key={post_name || slug}
                    xs={12}
                    sm={6}
                    md={4}
                    img={url || getImage(_embedded)}
                    title={post_title || (title && title.rendered)}
                    description={post_excerpt || (excerpt && excerpt.rendered)}
                    contentLink={`/${fixPostType(
                      post_type || type || slug,
                    )}/${post_name || slug}`}
                  />
                ),
              )
            : Array.from(Array(3), (_, i) => (
                <Card xs={12} sm={6} lg={4} key={i} />
              ))}
        </Grid>
        <ButtonWrap onClickLeft={onClick} linkRight={null} />
      </div>
    </Section>
  );
}

export default React.memo(GlossaryCollection);
