import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

function PostSkeleton() {
  return (
    <>
      <Skeleton width={120} height={40} />
      <Skeleton width="100%" height={40} />
      <Skeleton width="100%" height={40} />
      <Skeleton width="100%" height={40} />
      <br />
      <Skeleton width={120} height={40} />
      <Skeleton width="100%" height={40} />
      <Skeleton width="100%" height={40} />
    </>
  );
}

export default PostSkeleton;
