import React, { useState } from 'react';
import Placeholder from '../../placeholder.jpg';

import { withStyles } from '@material-ui/core/styles';

function Image(props) {
  const [loaded, setLoaded] = useState(false);
  const { classes, className, alt, style, src } = props;

  function handleOnLoad() {
    setLoaded(true);
  }

  return (
    <>
      {!loaded && (
        <img
          style={style}
          className={className}
          alt="Universal Metals Placeholder"
          src={Placeholder}
        />
      )}
      <img
        style={style}
        alt={alt}
        className={[loaded ? classes.shown : classes.notShown, className].join(
          ' ',
        )}
        onLoad={handleOnLoad}
        src={src}
      />
    </>
  );
}

const styles = theme => ({
  notShown: {
    opacity: 0,
    filter: 'blur(4px)',
  },
  shown: {
    opacity: 1,
    filter: 'blur(0)',
    transition: 'filter 1s',
  },
});

export default withStyles(styles)(Image);
