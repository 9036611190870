import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import WPAPI from 'wpapi';

import Main from '../../components/layouts/main';

import { envUrl } from '../../configs/MetalConfig';

import withLayoutController from '../../HOCs/withLayoutController';

const MainWithLayoutController = withLayoutController(Main);

const local = `${envUrl}wp-json/`;
export const wp = new WPAPI({ endpoint: local });

function SinglePage({ match, formObj, openModal }) {
  const location = useLocation();
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const [state, setState] = useState({
    post: null,
  });

  useEffect(() => {
    setState({ post: null });
    wp.pages()
      .param('slug', location.pathname)
      .param('_', '2024-09-07') // Expire cache for todays date
      .embed()
      .then(data => {
        const postData = data[0];
        if (!postData) {
          history.replace(`404/${routeMatch.params.pageSlug}`);
          return;
        }

        setState({
          post: postData,
        });
      })
      .catch(e => history.go(`404`));
  }, [location.pathname]);

  //controller to handle layouts and route changes
  //checking what post template is selected
  //if it's sidebar or not

  return (
    <>
      <MainWithLayoutController
        title={state.post ? state.post.title.rendered : null}
        formObj={formObj}
        postData={state.post}
        sidebar={state.post && state.post.acf ? state.post.acf.sidebar : false}
        openModal={openModal}
      />
    </>
  );
}

export default SinglePage;
