import React from 'react';

import Section from '../../section/section';
import ButtonWrap from '../../button-wrap/button-wrap';

function Simple({ index, sectionContent, openModal }) {
  return (
    <Section index={index} style={{ padding: '60px 0' }}>
      <div className="section-wrap">
        <div>
          <h2 style={{ margin: 0 }}>{sectionContent.sectionTitle}</h2>
          <div
            style={{ textAlign: 'justify' }}
            dangerouslySetInnerHTML={{
              __html: sectionContent.sectionDescription,
            }}
          />
          <ButtonWrap onClickLeft={openModal} linkRight={'yeah'} />
        </div>
      </div>
    </Section>
  );
}

export default Simple;
