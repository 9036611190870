import React, { useState } from 'react';

import { TextField, MenuItem } from '@material-ui/core';

import CardBody from '../card-body/card-body';
import Button from '../button/button';

import {
  metalStuffs,
  calculateWeight,
  shapes,
} from './tools-data/metalWeightCalc';

const initialState = {
  material: 'alloySteel',
  shape: 'round',
  alloy: metalStuffs.alloySteel[0],
  pieces: 1,
  dim1: {
    input: null,
    units: 'in',
  },
  dim2: {
    input: null,
    units: 'in',
  },
  dim3: {
    input: null,
    units: 'in',
  },
  dim4: {
    input: null,
    units: 'in',
  },
  pieceWeight: 0,
  totalWeight: 0,
};

function MetalWeightCalculator() {
  const [state, setState] = useState(initialState);

  return (
    <>
      <CardBody title="Metals Weight Calculator">
        <form
          name="weightcalc"
          onSubmit={e => {
            e.preventDefault();
            const [pieceWeight, totalWeight] = calculateWeight(state);
            setState(s => ({ ...s, pieceWeight, totalWeight }));
          }}
        >
          <TextField
            id="length-from-types"
            select
            label="Material"
            variant="filled"
            style={{ background: 'white', marginRight: 10, minWidth: 110 }}
            name="Material"
            value={state.material}
            onChange={e => {
              const material = e.target.value;
              setState(s => ({
                ...s,
                alloy: metalStuffs[material][0],
                material,
              }));
            }}
          >
            <MenuItem value="alloySteel">Alloy Steel</MenuItem>
            <MenuItem value="aluminum">Aluminum</MenuItem>
            <MenuItem value="brass">Brass</MenuItem>
            <MenuItem value="bronze">Bronze</MenuItem>
            <MenuItem value="copper">Copper</MenuItem>
            <MenuItem value="copperAlloy">Copper Alloys</MenuItem>
            <MenuItem value="magnesium">Magnesium</MenuItem>
            <MenuItem value="nickel">Nickel</MenuItem>
            <MenuItem value="plastic">Plastic</MenuItem>
            <MenuItem value="stainless">Stainless</MenuItem>
            <MenuItem value="steel">Steel</MenuItem>
            <MenuItem value="titanium">Titanium</MenuItem>
            <MenuItem value="zinc">Zinc</MenuItem>
          </TextField>
          <TextField
            id="length-from-types"
            select
            label="Alloy"
            variant="filled"
            style={{ background: 'white', marginRight: 10, minWidth: 110 }}
            name="alloy"
            onChange={e => {
              const alloyName = e.target.value;
              setState(s => ({
                ...s,
                alloy: metalStuffs[s.material].find(
                  // eslint-disable-next-line radix
                  alloys => alloys.name == alloyName,
                ),
              }));
            }}
            value={state.alloy.name}
          >
            {metalStuffs[state.material].map(metal => (
              <MenuItem key={metal.name} value={metal.name}>
                {metal.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="length-from-types"
            select
            label="Shape"
            variant="filled"
            style={{ background: 'white', marginRight: 10, minWidth: 110 }}
            name="matshape"
            onChange={e => {
              const shape = e.target.value;
              setState(s => ({ ...s, shape }));
            }}
            value={state.shape}
          >
            <MenuItem value="round">Round</MenuItem>
            <MenuItem value="flat">Flat</MenuItem>
            <MenuItem value="hex">Hexagonal</MenuItem>
            <MenuItem value="pipe">Pipe</MenuItem>
            <MenuItem value="plate">Plate</MenuItem>
            <MenuItem value="rectangle">Rectangle</MenuItem>
            <MenuItem value="rectangleTube">Rectangle Tube</MenuItem>
            <MenuItem value="ring">Ring</MenuItem>
            <MenuItem value="sheet">Sheet</MenuItem>
            <MenuItem value="square">Square</MenuItem>
            <MenuItem value="squareTube">Square Tube</MenuItem>
            <MenuItem value="tube">Tube</MenuItem>
          </TextField>
          <TextField
            type="number"
            name="pieces"
            value={state.pieces}
            onChange={e => {
              const pieces = e.target.value;
              setState(s => ({ ...s, pieces }));
            }}
            label="Pieces"
            multiline
            variant="filled"
          />
          <div className="size-info-wrap">
            <div>
              <strong>Enter size information</strong>
            </div>
            {shapes[state.shape].labels.map((label, i) => (
              <div className="text-fields" style={{ marginBottom: 15 }}>
                <TextField
                  id={`dim${i + 1}`}
                  style={{ marginRight: 10, minWidth: 110 }}
                  type="number"
                  name={`dim${i + 1}`}
                  value={state[`dim${i + 1}`].input}
                  onChange={e => {
                    const dimInput = e.target.value;
                    const currentDim = `dim${i + 1}`;
                    setState(s => ({
                      ...s,
                      [currentDim]: {
                        ...s[currentDim],
                        input: dimInput,
                      },
                    }));
                  }}
                  label={label}
                  multiline
                  variant="filled"
                />
                <TextField
                  id="length-from-types"
                  select
                  label="Units"
                  variant="filled"
                  style={{
                    background: 'white',
                    marginRight: 10,
                    minWidth: 110,
                  }}
                  name={`unitsdim${i + 1}`}
                  value={state[`dim${i + 1}`].units}
                  onChange={e => {
                    const dimUnits = e.target.value;
                    const currentDim = `dim${i + 1}`;
                    setState(s => ({
                      ...s,
                      [currentDim]: {
                        ...s[currentDim],
                        units: dimUnits,
                      },
                    }));
                  }}
                >
                  <MenuItem value="in">inches</MenuItem>
                  <MenuItem value="ft">feet</MenuItem>
                  <MenuItem value="yd">yards</MenuItem>
                  <MenuItem value="mm">mm</MenuItem>
                  <MenuItem value="cm">cm</MenuItem>
                  <MenuItem value="m">m</MenuItem>
                </TextField>
              </div>
            ))}
          </div>
          <div style={{ marginBottom: 20 }}>
            <Button type="submit" style={{ marginRight: 10 }}>
              Calculate
            </Button>
            <Button
              type="reset"
              name="reset"
              value="Reset"
              onClick={() => setState(initialState)}
            >
              Reset
            </Button>
          </div>
          <strong>Piece Weight (lbs) </strong>
          <span>{state.pieceWeight.toFixed(2)}</span>
          <br />
          <strong>Total Weight (lbs) </strong>
          <span>{state.totalWeight.toFixed(2)}</span>
        </form>
      </CardBody>
    </>
  );
}

export default MetalWeightCalculator;
