import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Tab, Tabs } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { metalConfigs, wp, getCurrentMetal } from '../../configs/MetalConfig';
import MetalTabContent from '../../components/metal-tab-content/MetalTabContent';
import MetalTabSkeleton from '../../components/metal-tab-content/metal-tab-skelton';
import TabPanel from '../../components/tab-panel/tab-panel';
import ButtonWrap from '../../components/button-wrap/button-wrap';

import { cache } from '../../utils/utils';

import Main from '../../components/layouts/main';

import './inventory.scss';

const useStyles = makeStyles(theme => {
  return {
    tabRoot: {
      background: 'white',
      flex: '1 1',
      maxWidth: 'unset',
      opacity: 1,
      padding: '20px 0',
      textTransform: 'uppercase',
      fontSize: 18,
      letterSpacing: 1,
      color: 'black',
      '& > span': {
        fontFamily: theme.typography.fontFamily,
      },
    },
  };
});

const dummyArr = [];

function Inventory(props) {
  const { metal } = props.match.params;
  const { openModal } = props;
  const currentMetal = metal
    ? getCurrentMetal(metal)
    : getCurrentMetal('titanium');

  const theme = useTheme();
  const isBreakpointMD = useMediaQuery(theme.breakpoints.down('md'));

  const cachedData = cache.get('inventory');

  const inventoryData = cachedData
    ? cachedData.inventory
    : {
        [currentMetal.slug]: dummyArr,
      };

  const [state, setState] = useState({
    posts: {
      inventory: {
        ...inventoryData,
      },
    },
    loading: false,
  });

  const { posts, loading } = state;
  const styles = useStyles();

  useEffect(() => {
    if (
      !posts.inventory[currentMetal.slug] ||
      posts.inventory[currentMetal.slug].length === 0
    ) {
      setState(s => ({
        ...s,
        loading: true,
      }));

      wp[currentMetal.shortName]().then(data => {
        if (data) {
          setState(s => {
            cache.save('inventory', {
              inventory: { ...s.posts.inventory, [currentMetal.slug]: data },
            });

            return {
              ...s,
              posts: {
                inventory: {
                  ...s.posts.inventory,
                  [currentMetal.slug]: data,
                },
              },
              loading: false,
            };
          });
        }
      });
    }
  }, [currentMetal.shortName, currentMetal.slug, posts.inventory]);

  return (
    <Main
      layout="title-bar"
      title={currentMetal.name}
      classNames={['inventory-page']}
      description={currentMetal.description}
      seoTitle={currentMetal.seoTitle}
      {...props}
    >
      <div className="inventory-wrap">
        <div
          style={{
            maxWidth: 1600,
            margin: 'auto',
            padding: '40px 0 80px',
          }}
        >
          <Tabs
            orientation={isBreakpointMD ? 'vertical' : 'horizontal'}
            style={{
              borderRadius: 4,
              boxShadow: '1px 1px 23px #0000000a',
              maxWidth: 1300,
              margin: '-70px auto 25px',
              padding: 0,
            }}
            className="tabs-inventory"
            value={currentMetal.id}
            aria-label="navigation tabs for each type of metal"
          >
            {metalConfigs.map((metalConfig, i) => (
              <Tab
                key={metalConfig.id}
                label={metalConfig.name}
                component={Link}
                to={`/inventory/${metalConfig.slug}/`}
                className={styles.tabRoot}
              />
            ))}
          </Tabs>

          <TabPanel value={currentMetal.id} index={currentMetal.id}>
            {loading ? (
              <MetalTabSkeleton />
            ) : (
              <MetalTabContent
                posts={posts.inventory[currentMetal.slug] || []}
                currentMetal={currentMetal}
              />
            )}
          </TabPanel>

          <ButtonWrap onClickLeft={openModal} linkRight={null} />
        </div>
      </div>
    </Main>
  );
}

export default Inventory;
