import React from 'react';
import { Route, Link, useLocation, useHistory } from 'react-router-dom';

import { Paper, Grid, Box, Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LengthConversion from '../../components/tools/length-conversion';
import DecimalToFractions from '../../components/tools/decimal-to-fractions';
import RoundToSquare from '../../components/tools/round-to-square';
import TemperatureConversion from '../../components/tools/temperture-conversion';
import WeightConversion from '../../components/tools/weight-conversion';
import SheetMetalGaugeConversion from '../../components/tools/sheet-metal-gauge-conversion';
import SteelHardnessConversion from '../../components/tools/steel-hardness-conversion';
import MetalWeightCalculator from '../../components/tools/metal-weight-calculator';

import Main from '../../components/layouts/main';

import './tools.scss';

const ToolsInfo = [
  {
    id: 0,
    component: LengthConversion,
    link: '/tools/conversion/',
    text: 'Unit Conversion',
  },
  {
    id: 1,
    component: DecimalToFractions,
    link: '/tools/decimal-to-fraction/',
    text: 'Decimal To Fraction',
  },
  {
    id: 2,
    component: RoundToSquare,
    link: '/tools/round-to-square/',
    text: 'Round To Square Calculator',
  },
  {
    id: 3,
    component: TemperatureConversion,
    link: '/tools/temperature-conversion/',
    text: 'Temperature Conversion',
  },
  {
    id: 4,
    component: WeightConversion,
    link: '/tools/weight-conversion/',
    text: 'Weight Conversion',
  },
  {
    id: 5,
    component: SheetMetalGaugeConversion,
    link: '/tools/sheet-metal-gauge-conversion/',
    text: 'Sheet Metal Gauge Conversion',
  },
  {
    id: 6,
    component: SteelHardnessConversion,
    link: '/tools/steel-hardness-conversion/',
    text: 'Steel Hardness Conversion',
  },
  {
    id: 7,
    component: MetalWeightCalculator,
    link: '/tools/metal-weight-conversion/',
    text: 'Metal Weight Calculator',
  },
];

function Tools({ classes, ...props }) {
  const location = useLocation();
  const history = useHistory();

  const selectedTitle = ToolsInfo.find(
    toolInfo => toolInfo.link === location.pathname,
  );

  if (location.pathname === '/tools' || location.pathname === '/tools/') {
    history.push('/tools/conversion/');
  }

  return (
    <Main
      layout="title-bar"
      title={selectedTitle && selectedTitle.text ? selectedTitle.text : 'Tools'}
    >
      <div style={{ maxWidth: 1400, margin: 'auto', padding: '40px 0' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <Paper elevation={0} style={{ borderRadius: 8 }}>
              <Box p={3}>
                <Tabs
                  value={selectedTitle ? selectedTitle.id : 0}
                  aria-label="simple tabs example"
                  orientation="vertical"
                >
                  {ToolsInfo.map(tool => (
                    <Tab
                      className={classes.tabRoot}
                      key={tool.text}
                      label={tool.text}
                      component={Link}
                      to={tool.link}
                    />
                  ))}
                </Tabs>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} lg={8}>
            {location.pathname !== '/tools/' ? (
              ToolsInfo.map((tool, i) => (
                <Route
                  key={i}
                  exact
                  path={tool.link}
                  component={tool.component}
                />
              ))
            ) : (
              <div>Tools!</div>
            )}
          </Grid>
        </Grid>
      </div>
    </Main>
  );
}

const styles = theme => ({
  tabRoot: {
    maxWidth: 'unset',
    textAlign: 'left',
    '& > span': {
      fontFamily: theme.typography.fontFamily,
    },
    '&.Mui-selected': {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
});

export default React.memo(withStyles(styles)(Tools));
