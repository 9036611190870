const redirects = [
  {
    oldPath: '/stainless-steel-grades',
    newPath: '/inventory/stainless-steel/',
  },
  { oldPath: '/titanium-grades/', newPath: '/inventory/titanium/' },
  { oldPath: '/alloy-steel-grades/', newPath: '/inventory/alloy-steel/' },
  { oldPath: '/cobalt-alloy-grades/', newPath: '/inventory/cobalt-alloy/' },
  { oldPath: '/nickel-grades/', newPath: '/inventory/nickel/' },

  { oldPath: '/length-converstion', newPath: '/tools/conversion/' },
  { oldPath: '/decimal-to-fractions', newPath: '/tools/decimal-to-fraction/' },
  { oldPath: '/round-to-square-calculator', newPath: '/tools/round-to-square/' },
  {
    oldPath: '/temperature-conversion',
    newPath: '/tools/temperature-conversion/',
  },
  { oldPath: '/weight-conversion', newPath: '/tools/weight-conversion/' },
  {
    oldPath: '/sheet-metal-gauge-conversions/',
    newPath: '/tools/sheet-metal-gauge-conversion/',
  },
  {
    oldPath: '/steel-hardness-conversion-calculator/',
    newPath: '/tools/steel-hardness-conversion/',
  },
  {
    oldPath: '/metal-weight-calculator/',
    newPath: '/tools/metal-weight-conversion/',
  },
  {
    oldPath: '/yield-algorithm-2',
    newPath: '/about-us-2/',
  },
  {
    oldPath: '/quote',
    newPath: '/',
  },
  {
    oldPath: '/approvals-and-customer-base',
    newPath: '/about-us-2/',
  },
  {
    oldPath: '/approvals-and-customer-base',
    newPath: '/about-us-2/',
  },
  {
    oldPath: '/incoming-stock',
    newPath: '/inventory/',
  },
  {
    oldPath: '/mission-statement',
    newPath: '/about-us-2/',
  },
];

export default redirects;
