import React from 'react';

import { Parallax } from 'react-parallax';

import Section from '../../section/section';
import Button from '../../button/button';

const insideStyles = {
  color: 'white',
  // padding: 20,
  padding: '0 30px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  textAlign: 'center',
  width: '100%',
};

function Hero({ index, sectionContent, background, key }) {
  const { sectionTitle, sectionDescription } = sectionContent;

  return (
    <Parallax
      strength={300}
      bgImage={background.url}
      style={{ borderRadius: 8 }}
      // https://images.unsplash.com/photo-1498092651296-641e88c3b057?auto=format&fit=crop&w=1778&q=60&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D
      // bgImageStyle={{
      //   objectFit: 'cover',
      // }}
    >
      <Section index={index} style={{ background: 'none', padding: 0 }}>
        <div className="section-wrap" style={{ height: 400 }}>
          <div style={insideStyles}>
            <h1 style={{ margin: 0 }}>{sectionTitle}</h1>
            <div
              style={{ fontSize: 20, margin: '10px 0 20px' }}
              dangerouslySetInnerHTML={{ __html: sectionDescription }}
            ></div>
            <Button variant="contained">Get Quote</Button>
          </div>
        </div>
      </Section>
    </Parallax>
  );
}

export default Hero;
