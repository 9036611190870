import React from 'react';

import Section from '../../section/section';
import SimpleGallery from '../../simple-gallery/simple-gallery';

function Gallery({ gallery, index, sectionContent, openModal }) {
  return (
    <Section index={index} style={{ padding: '60px 0' }}>
      <div className="section-wrap">
        <SimpleGallery
          imagesArr={gallery}
          title={sectionContent.sectionTitle}
          description={sectionContent.sectionDescription}
          openModal={openModal}
        />
      </div>
    </Section>
  );
}

export default Gallery;
