import moment from 'moment';
import { DEFAULT_IMAGES } from '../configs/MetalConfig';

export const generateArrayItems = (looper = 6, item) => {
  Array.from(Array(looper), (_, i) => item(i));
};

export const isCacheExpired = date => {
  return moment().isAfter(moment(date).add(15, 'm'));
};

export function getFeaturedImage(imageData, size = 'medium_large') {
  const defaultImageUrl = DEFAULT_IMAGES.featuredImage; // Default image URL

  if (!imageData) {
    return defaultImageUrl;
  }

  const featuredMedia = imageData['wp:featuredmedia'];
  const image = featuredMedia ? featuredMedia[0]?.source_url : imageData?.url;
  const sizes = featuredMedia ? featuredMedia[0]?.media_details?.sizes : imageData?.sizes;

  return sizes ? sizes[size]?.source_url || sizes[size] || image : defaultImageUrl;
}

const save = (key, data) => {
  localStorage.setItem(
    key,
    JSON.stringify({
      date: moment(),
      data,
    }),
  );
};

const get = key => {
  const jsonString = localStorage.getItem(key);
  if (jsonString) {
    const parsedData = JSON.parse(localStorage.getItem(key));

    const cacheExpired =
      parsedData && parsedData.date ? isCacheExpired(parsedData.date) : null;

    return !cacheExpired && parsedData && parsedData.data
      ? parsedData.data
      : null;
  }

  return null;
};

export const cache = {
  save,
  get,
};
