import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import WPAPI from 'wpapi';

import Main from '../../components/layouts/main';
import { envUrl } from '../../configs/MetalConfig';

import withLayoutController from '../../HOCs/withLayoutController';

const MainWithLayoutController = withLayoutController(Main);

const local = `${envUrl}wp-json/`;
export const wp = new WPAPI({ endpoint: local });
const namespace = 'wp/v2';

wp.service = wp.registerRoute(namespace, '/processes/(?P<id>)');

function ProcessesSingle({ match, formObj, openModal }) {
  const [state, setState] = useState({
    post: null,
    error: null,
  });
  const serviceSlug = match.params.service;

  useEffect(() => {
    setState({ post: null, error: null });
    wp.service()
      .param('slug', serviceSlug)
      .embed()
      .then(data => {
        if (data && data.length > 0) {
          setState({ post: data[0], error: null });
        } else {
          setState({ post: null, error: 'Not Found' });
        }
      })
      .catch(error => {
        setState({ post: null, error: error.message });
      });
  }, [serviceSlug]);

  if (state.error) {
    return <Redirect to="/404" />;
  }

  return (
    <MainWithLayoutController
      title={state.post && state.post.title ? state.post.title.rendered : null}
      postData={state.post}
      formObj={formObj}
      openModal={openModal}
    />
  );
}

export default ProcessesSingle;