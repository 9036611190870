import React from 'react';

function CardBody({ children, title }) {
  return (
    <div className="body-wrap" style={{ marginBottom: 20 }}>
      <h3>{title}</h3>
      <div className="body">{children}</div>
    </div>
  );
}

export default CardBody;
