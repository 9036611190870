import React, { useState } from 'react';

import { TextField, MenuItem } from '@material-ui/core';

import CardBody from '../card-body/card-body';

function TemperatureConversion() {
  const [state, setState] = useState({
    tempFrom: 'celsius',
    tempTo: 'fahrenheit',
    value: null,
    inputValue: 0,
  });

  let total = 0;

  const conversions = {
    kelvin: {
      from: () => state.inputValue * 1,
      to: kelvin => kelvin,
      name: 'Kelvin',
    },
    celsius: {
      from: () => state.inputValue * 1 + 273.15,
      to: kelvin => kelvin - 273.15,
      name: 'Celsius',
    },
    fahrenheit: {
      from: () => (state.inputValue * 1 + 459.67) * (5 / 9),
      to: kelvin => kelvin * (9 / 5) - 459.67,
      name: 'Fahrenheit',
    },
    rankine: {
      from: () => state.inputValue * 1 * (5 / 9),
      to: kelvin => kelvin * (9 / 5),
      name: 'Rankine',
    },
    delisle: {
      from: () => (373.15 - state.inputValue) * (2 / 3),
      to: kelvin => (373.15 - kelvin) * (3 / 2),
      name: 'Delisle',
    },
    newton: {
      from: () => state.inputValue * (100 / 33) + 273.15,
      to: kelvin => (kelvin - 273.15) * (33 / 100),
      name: 'Newton',
    },
    reaumur: {
      from: () => state.inputValue * (5 / 4) + 273.15,
      to: kelvin => (kelvin - 273.15) * (4 / 5),
      name: 'Réaumur',
    },
    romer: {
      from: () => (state.inputValue * 1 - 7.5) * (40 / 21) + 273.15,
      to: kelvin => (kelvin - 273.15) * (21 / 40) + 7.5,
      name: 'Rømer',
    },
  };

  const temperatureConverter = (e, from) => {
    const tempDir = from === 'from' ? 'tempFrom' : 'tempTo';
    const type = e.target.value;

    setState(s => ({
      ...s,
      [tempDir]: type,
    }));
  };

  const handleTextChange = e => {
    const inputValue = e.currentTarget.value;
    setState(s => ({ ...s, inputValue }));
  };

  if (state.tempFrom && state.tempTo) {
    const kelvins = conversions[state.tempFrom].from();
    total = conversions[state.tempTo].to(kelvins);
    total = total.toFixed(3);
  }

  return (
    <>
      <CardBody title="Temperature Converter">
        <TextField
          id="temperature-input"
          type="number"
          onChange={handleTextChange}
          value={state.inputValue}
          label="Temp"
          variant="filled"
          style={{ marginRight: 10 }}
        />
        <TextField
          id="temperature-from-types"
          onChange={e => temperatureConverter(e, 'from')}
          value={state.tempFrom}
          select
          name="from-type"
          label="From"
          variant="filled"
          style={{ background: 'white', marginRight: 10, minWidth: 110 }}
        >
          {Object.keys(conversions).map((conversion, i) => (
            <MenuItem key={i} value={conversion}>
              {conversions[conversion].name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="temperature-to-types"
          onChange={e => temperatureConverter(e, 'to')}
          value={state.tempTo}
          select
          name="to-type"
          label="To"
          variant="filled"
          style={{ background: 'white', marginRight: 10, minWidth: 110 }}
        >
          {Object.keys(conversions).map((conversion, i) => (
            <MenuItem key={i} value={conversion}>
              {conversions[conversion].name}
            </MenuItem>
          ))}
        </TextField>
        {total && (
          <div id="length-output">
            {total} {conversions[state.tempTo].name}
          </div>
        )}
      </CardBody>
    </>
  );
}

export default TemperatureConversion;
