import React, { useState } from 'react';

import { TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import CardBody from '../card-body/card-body';

const hb = [
  800,
  780,
  760,
  752,
  745,
  746,
  735,
  711,
  695,
  681,
  658,
  642,
  627,
  613,
  601,
  592,
  572,
  552,
  534,
  513,
  504,
  486,
  469,
  468,
  456,
  445,
  430,
  419,
  415,
  402,
  388,
  375,
  373,
  360,
  348,
  341,
  331,
  322,
  314,
  308,
  300,
  290,
  277,
  271,
  264,
  262,
  255,
  250,
  245,
  240,
  233,
  229,
  223,
  216,
  212,
  208,
  203,
  199,
  191,
  190,
  186,
  183,
  180,
  175,
  170,
  167,
  166,
  163,
  160,
  156,
  154,
  149,
  147,
  143,
  141,
  139,
  137,
  135,
  131,
  127,
  121,
  116,
  114,
  111,
  107,
  105,
  103,
  95,
  90,
  81,
  76,
];
const hrc = [
  72,
  71,
  70,
  69,
  68,
  67,
  66,
  65,
  64,
  63,
  62,
  61,
  60,
  59,
  58,
  57,
  56,
  55,
  54,
  53,
  52,
  51,
  50,
  49,
  48,
  47,
  46,
  45,
  44,
  43,
  42,
  41,
  40,
  39,
  38,
  37,
  36,
  35,
  34,
  33,
  32,
  31,
  30,
  29,
  28,
  27,
  26,
  25,
  24,
  23,
  22,
  21,
  20,
  19,
  18,
  17,
  16,
  15,
  14,
  13,
  12,
  11,
  10,
  9,
  7,
  6,
  5,
  4,
  3,
  2,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
];
const hrb = [
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  120,
  119,
  118,
  118,
  117,
  117,
  116,
  115,
  115,
  114,
  114,
  114,
  113,
  112,
  111,
  111,
  110,
  109,
  109,
  108,
  108,
  107,
  107,
  106,
  105,
  104,
  103,
  103,
  102,
  101,
  100,
  100,
  99,
  98,
  97,
  96,
  95,
  95,
  94,
  93,
  92,
  92,
  91,
  90,
  89,
  88,
  87,
  86,
  86,
  85,
  84,
  83,
  82,
  81,
  80,
  79,
  78,
  77,
  76,
  75,
  74,
  72,
  70,
  68,
  67,
  66,
  64,
  62,
  61,
  56,
  52,
  41,
  37,
];
const hv = [
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  746,
  727,
  694,
  649,
  589,
  567,
  549,
  531,
  505,
  497,
  490,
  474,
  458,
  448,
  438,
  424,
  406,
  393,
  388,
  376,
  361,
  351,
  342,
  332,
  320,
  311,
  303,
  292,
  285,
  277,
  271,
  262,
  258,
  255,
  252,
  247,
  241,
  235,
  227,
  222,
  218,
  210,
  201,
  199,
  197,
  186,
  184,
  183,
  180,
  178,
  175,
  172,
  168,
  162,
  160,
  158,
  152,
  149,
  147,
  146,
  144,
  142,
  140,
  137,
  134,
  129,
  127,
  124,
  121,
  118,
  115,
  112,
  108,
  104,
  95,
  85,
  80,
];

function convert(from, scale) {
  const max = hb.length;
  if (scale === 'hb') {
    for (let i = 0; i < max; i++) {
      if (hb[i] <= from) {
        return [hb[i], hrc[i], hrb[i], hv[i]];
      }
    }
  }
}

function SteelHardnessConversion() {
  const [state, setState] = useState({
    hb: 0,
    hrc: 0,
    hrb: 0,
    hv: 0,
    input: null,
    error: false,
  });

  const handleCalculation = (e, input) => {
    const [hbVal, hrcVal, hrbVal, hvVal] = convert(input, 'hb');
    setState(s => ({ ...s, hb: hbVal, hrc: hrcVal, hrb: hrbVal, hv: hvVal }));
  };

  const handleInput = e => {
    const input = e.currentTarget.value * 1;
    let error = false;

    if (input >= 75 && input <= 801) {
      handleCalculation(e, input);
    } else {
      error = 'Must Be Between 75 and 801';
    }

    setState(s => ({
      ...s,
      input,
      error,
    }));
  };

  return (
    <>
      <CardBody title="Steel Hardness Conversion Calculator">
        <form name="first">
          <TextField
            id="bhname"
            type="number"
            className="easypositive-integer"
            max="999"
            value={state.input}
            onChange={handleInput}
            label="Brinell Hardness HB"
            variant="filled"
            style={{ marginRight: 10 }}
          />
          <div className="result">
            <div className="group clearfix">
              <span>Rockwell HRC: {state.hrc}</span>
            </div>
            <div className="group clearfix">
              <span>Rockwell HRB: {state.hrb}</span>
            </div>
            <div className="group clearfix">
              <span>Vickers HV: {state.hv}</span>
            </div>
          </div>
        </form>
        {state.error && <Alert severity="error">{state.error}</Alert>}
      </CardBody>
    </>
  );
}

export default SteelHardnessConversion;
