import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';

import './slider.scss';

export default function Slider({
  children,
  autoPlay = true,
  numberOfSlides = 5,
}) {
  const [slideState, setSlideState] = useState({});
  const sliderRef = useRef();

  const calculate = (totalCards, slice, offset) => {
    const sliderChildren = sliderRef.current.children;

    const percentageWidth = 100 / totalCards;
    const slicedWidth = slice ? percentageWidth * slice : percentageWidth;
    const slicedTotal = slice ? 100 - slicedWidth * 2 : 100;
    const viewableCards = slice ? totalCards - 2 : totalCards;
    const actualWidth = slicedTotal / viewableCards;

    setSlideState({
      actualWidth,
      slicedWidth,
      childCount: sliderChildren.length,
      totalTranslated: slice ? slicedWidth - actualWidth : 0,
      totalCards,
      viewableCards,
    });

    for (let i = 0; i < sliderChildren.length; i++) {
      const child = sliderChildren[i];

      child.style.width = `${actualWidth}%`;
      child.style.flex = `0 0 ${actualWidth}%`;
      child.style.maxWidth = `${actualWidth}%`;
    }
  };

  const setCalculations = () => {
    if (window.innerWidth > 1000) {
      calculate(numberOfSlides, 0, 0);
    } else if (window.innerWidth > 800) {
      calculate(4, 1 / 3, -1);
    } else {
      calculate(3, 1 / 4, 0);
    }
  };

  const setTotalTranslated = total => {
    setSlideState({
      ...slideState,
      totalTranslated: total,
    });
  };

  const movePrev = () => {
    const total = slideState.totalTranslated + slideState.actualWidth;

    if (total <= slideState.actualWidth) {
      setTotalTranslated(total);
    }
  };

  function moveNext() {
    const {
      childCount,
      totalTranslated,
      slicedWidth,
      actualWidth,
      viewableCards,
    } = slideState;

    const moveSlidesPast = 1;

    const maxWidth =
      childCount * actualWidth - actualWidth * viewableCards - slicedWidth;

    const total = totalTranslated - actualWidth * moveSlidesPast;

    if (Math.abs(total) <= maxWidth) {
      setTotalTranslated(total);
    } else {
      setTotalTranslated(0);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', setCalculations);

    return () => {
      window.removeEventListener('resize', setCalculations);
    };
  }, []);

  useLayoutEffect(() => {
    setCalculations();
  }, []);

  useEffect(() => {
    let timeout;
    if (slideState.actualWidth && autoPlay) {
      timeout = setTimeout(() => moveNext(slideState), 2000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [children, slideState.totalTranslated, autoPlay]);

  return (
    <>
      <div className="slider">
        <div
          ref={sliderRef}
          className="slider-container"
          style={{
            transform: `translateX(${slideState.totalTranslated}%)`,
          }}
        >
          {children}
        </div>
        {!autoPlay && (
          <>
            <div
              style={{ width: `${slideState.slicedWidth}%` }}
              className="arrow left"
              onClick={movePrev}
            >
              <span className="arrow-text">Arrow Left</span>
              {'<'}
            </div>
            <div
              style={{ width: `${slideState.slicedWidth}%` }}
              className="arrow right"
              onClick={moveNext}
            >
              <span className="arrow-text">Arrow Right</span>
              {'>'}
            </div>
          </>
        )}
      </div>
    </>
  );
}
