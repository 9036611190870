import React, { useEffect, useState } from 'react';
import { wp, getCurrentMetal } from '../../configs/MetalConfig';

import Main from '../../components/layouts/main';

import './spec.scss';

// http://localhost:8080/wp-json/wp/v2/alloy_steel_specs?slug=ams-6359
function Spec({ match, formObj }) {
  const currentMetal = getCurrentMetal(match.params.metal);
  const specSlug = match.params.spec;
  const [state, setState] = useState({
    post: null,
  });

  useEffect(() => {
    wp[currentMetal.specName]()
      .param('slug', specSlug)
      .param('_', '2024-09-07') // Expire cache for todays date
      .then(data =>
        setState({
          post: data[0],
        }),
      );
  }, [currentMetal.specName, specSlug]);

  return (
    <Main
      title={state.post && state.post.title ? state.post.title.rendered : null}
      postData={state.post}
      layout="sidebar"
      formObj={formObj}
    />
  );
}

export default Spec;
