import React from 'react';

import SideMedia from '../../components/sections/side-media/side-media';
import SideContentSection from '../../components/sections/side-content-section/side-content-section';
import Gallery from '../../components/sections/gallery/gallery';
import ImageSlider from '../../components/sections/image-slider/image-slider';
import PostCollection from '../../components/sections/post-collection/post-collection';
import CardSection from '../../components/sections/card/card';
import Hero from '../../components/sections/hero/hero';
import Simple from '../../components/sections/simple/simple';
import Icon from '../../components/sections/icon/icon';

import SectionSkeleton from '../../components/skeletons/section-skeleton/section-skeleton';

import withLooping from '../../HOCs/withLooping';

const SectionSkeltonWithLooping = withLooping(SectionSkeleton);

const componentList = {
  postCollection: PostCollection,
  hero: Hero,
  icon: Icon,
  card: CardSection,
  simpleText: Simple,
  imageSlider: ImageSlider,
  gallery: Gallery,
  media: SideMedia,
  sideContent: SideContentSection,
};

const haveLayouts = layouts => layouts.length !== 0;

function FlexibleLayout({ layouts = [], openModal }) {
  return haveLayouts(layouts) ? (
    layouts.map((layout, i) => {
      const { acf_fc_layout } = layout;
      const Component = componentList[acf_fc_layout];

      if (!Component) {
        return null;
      }

      return (
        <React.Fragment key={`${acf_fc_layout}-${i}`}>
          <Component
            index={i}
            className={acf_fc_layout}
            openModal={openModal}
            {...layout}
          />
        </React.Fragment>
      );
    })
  ) : (
    <SectionSkeltonWithLooping looper={5} index={0} />
  );
}

export default React.memo(FlexibleLayout);
