import React from 'react';
import { useLocation } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';

import Button from '../button/button';

import { envUrl } from '../../configs/MetalConfig';
import ReactGA from '../../analytics';

function ButtonWrap({ onClickLeft, linkRight, classes, color = 'black' }) {
  const location = useLocation();

  const handleProductLineCardClick = () => {
    ReactGA.event({
      category: 'ButtonClick',
      action: 'Clicked Line Card',
      label: `Line Card - ${location.pathname}`,
    });
  };

  return (
    <div
      style={{
        marginTop: 26,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Button
        onClick={onClickLeft}
        variant="contained"
        style={{ marginBottom: 10 }}
      >
        Get Quote
      </Button>
      <a
        href={`${envUrl}wp-content/uploads/2019/08/UM_LineCard.pdf`}
        target="_blank"
        className={classes.secondaryLinkWrap}
        onClick={handleProductLineCardClick}
      >
        <GetAppIcon color={color === 'black' ? 'primary' : 'secondary'} />
        <span style={{ color }}>Product Line Card</span>
      </a>
    </div>
  );
}

const styles = theme => ({
  secondaryLinkWrap: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default withStyles(styles)(ButtonWrap);
