import React, { useState } from 'react';
import num2fraction from 'num2fraction';

import { TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

function DecimalToFractions() {
  const [state, setState] = useState({
    num2frac: '',
    frac2num: '',
    num2fracInput: '',
    frac2numInput: '',
    frac2NumErrors: false,
    num2fracErrors: false,
  });

  const handleConversionType = (e, conversionType) => {
    const tools = {
      num2frac: {
        regex: '^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$',
        operation: decimalInput => num2fraction(decimalInput),
      },
      frac2num: {
        regex: '^[+-]?([0-9]+([/][0-9]*)?|[/][0-9]+)$',
        operation: fracInput =>
          fracInput.split('/').reduce((accum, nextVal) => accum / nextVal),
      },
    };
    const input = e.currentTarget.value;
    const type = tools[conversionType];
    const floatRegExp = new RegExp(type.regex);
    const found = floatRegExp.test(input);
    const result = found && input !== '' ? type.operation(input) : '';

    setState(s => ({
      ...s,
      [conversionType]: result,
      [`${conversionType}Input`]: input,
      [`${conversionType}Errors`]: !(found || input === ''),
    }));
  };

  return (
    <>
      <div className="body-wrap" style={{ marginBottom: 20 }}>
        <h3>Decimal to Fraction Converter</h3>
        <div className="body">
          <TextField
            id="decimal"
            type="text"
            onChange={e => handleConversionType(e, 'num2frac')}
            value={state.num2fracInput}
            label="Decimal"
            multiline
            variant="filled"
          />
          {state.num2frac && (
            <div className="length-output">Result: {state.num2frac}</div>
          )}
          {state.num2fracErrors && (
            <Alert severity="error">
              Invalid input given: {state.num2fracInput}
              <br />
              Format like this: 1.10
            </Alert>
          )}
        </div>
      </div>
      <div className="body-wrap">
        <h3>Fraction to Decimal Converter</h3>
        <div className="body">
          <TextField
            id="fraction-input"
            type="text"
            onChange={e => handleConversionType(e, 'frac2num')}
            value={state.frac2numInput}
            label="Fraction"
            multiline
            variant="filled"
          />
          {state.frac2num && (
            <div className="length-output">Result: {state.frac2num}</div>
          )}
          {state.frac2numErrors && (
            <Alert severity="error">
              Invalid input given: {state.frac2numInput}
              <br />
              Format like this: 1/10
            </Alert>
          )}
        </div>
      </div>
    </>
  );
}

export default DecimalToFractions;
