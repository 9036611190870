import React from 'react';

function withLooping(Component) {
  return function InnerComponent({ looper, index }) {
    return Array.from(Array(looper), (_, i) => (
      <Component key={i} index={index || i} />
    ));
  };
}

export default withLooping;
