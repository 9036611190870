import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { Grid, IconButton, MenuItem } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Skeleton } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';

import { envUrl } from '../../configs/MetalConfig';
import { cache } from '../../utils/utils';

import './nav.scss';

const links = [
  { url: '/', title: 'Home' },
  { url: '/inventory/stainless-steel', title: 'Inventory' },
  { url: '/incoming-stock', title: 'Incoming Stock' },
  { url: '/about-us-2', title: 'About Us' },
  { url: '/tools', title: 'Tools' },
];

function Nav({ openModal }) {
  const [menu, setMenu] = useState(cache.get('menu') ?? []);
  const [isOpen, setOpen] = useState(null);
  const [currentChildItem, setChildItem] = useState(null);
  const theme = useTheme();
  const isBreakpointMD = useMediaQuery(theme.breakpoints.down('md'));

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  const handleMenuClick = () => {
    setOpen(null);
    setChildItem(null);
  };

  useEffect(() => {
    if (!menu || menu.length === 0) {
      axios
        .get(`${envUrl}wp-json/menus/v1/menus/17`)
        .then(res => {
          cache.save('menu', res.data.items);
          setMenu(res.data.items);
        })
        .catch(e => {
          setMenu(links);
        });
    }
  }, [links]);

  function handleUrlReplacement(url) {
    return url.replace(envUrl, '/')
      .replace('https://universalmetals.com', '')
      .replace('https://www.universalmetals.com', '');
  }

  return (
    <div
      className={`nav ${isBreakpointMD ? 'is-mobile' : 'is-desktop'} ${
        isOpen ? 'is-open' : 'is-closed'
      }`}
      style={{
        minHeight: 60,
        display: 'flex',
      }}
    >
      <div className="nav-wrap">
        <div className="logo-wrap">
          <Link to="/">
            <img
              style={{ maxHeight: 45 }}
              alt="Aerospace Metal Supplier & Inventory Management, Universal Metals"
              src={`${envUrl}wp-content/uploads/2017/07/umnewlogonew.png`}
            />
          </Link>
        </div>
        {isBreakpointMD && (
          <IconButton
            edge="start"
            // className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleOpen}
            className="hamburger"
          >
            <MenuIcon />
          </IconButton>
        )}
        <div className="menu-item-wrap">
          {menu.length !== 0 ? (
            <>
              {menu.map((item, i) => (
                <div
                  key={i}
                  className={`menu-item parent-menu-item-${i} ${
                    item.child_items ? 'has-children' : 'no-children'
                  }`}
                >
                  <Link
                    key={i}
                    to={handleUrlReplacement(item.url)}
                    onClick={() => handleMenuClick()}
                    // style={{ padding: '5px 7px', borderRadius: '8px' }}
                  >
                    {item.title}
                  </Link>
                  {item.child_items && (
                    <>
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={() => {
                          if (currentChildItem === i) {
                            return setChildItem(null);
                          }

                          setChildItem(i);
                        }}
                        aria-label={`More Menu Children Items for ${item.title}`}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                      <div
                        style={{
                          opacity: currentChildItem === i ? 1 : 0,
                        }}
                        className={`submenu-child ${
                          currentChildItem === i ? 'active' : 'not-active'
                        }`}
                      >
                        {item.child_items.map((childItem, i) => (
                          <Link
                            key={i}
                            to={handleUrlReplacement(childItem.url)}
                            onClick={() => handleMenuClick()}
                            aria-hidden={currentChildItem === i ? null : true}
                            tabIndex="-1"
                            // style={{ padding: '5px 7px', borderRadius: '8px' }}
                          >
                            <MenuItem>{childItem.title}</MenuItem>
                          </Link>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              ))}
              <div className={'menu-item parent-menu-item-last no-children'}>
                <a
                  href="#quote"
                  onClick={e => {
                    e.preventDefault();
                    openModal();
                  }}
                >
                  Quote
                </a>
              </div>
            </>
          ) : (
            <Grid container>
              <Skeleton
                style={{ marginRight: '10px' }}
                height={40}
                width={100}
              />
              <Skeleton
                style={{ marginRight: '10px' }}
                height={40}
                width={120}
              />
              <Skeleton
                style={{ marginRight: '10px' }}
                height={40}
                width={90}
              />
              <Skeleton height={40} width={130} />
            </Grid>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(Nav);
