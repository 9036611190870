import React from 'react';
import classNames from 'classnames';

import './section.scss';

function Section({ index, children, ...props }) {
  return (
    <div
      {...props}
      className={classNames([
        'd-section',
        index % 2 === 0 ? 'even' : 'odd',
        props.className,
      ])}
    >
      {children}
    </div>
  );
}

export default Section;
