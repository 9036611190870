import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import Grid from '@material-ui/core/Grid';

import './metal-tab-content.css';

function FormRow({ children }) {
  return (
    <React.Fragment>
      <Grid item xs={12} sm={6} lg={3}>
        <div className="metal-wrapper">{children}</div>
      </Grid>
    </React.Fragment>
  );
}

function MetalTabSkelton() {
  return (
    <Grid container spacing={4}>
      {Array.from(Array(12), (_, i) => (
        <FormRow key={i}>
          <h2>
            <Skeleton variant="rect" animation="wave" height={47} />
          </h2>
          <div className="content-wrap">
            <div className="spec-content-wrap" style={{ marginBottom: 20 }}>
              <h3>Specs</h3>
              <div className="tag-wrap">
                <Skeleton
                  key={i}
                  style={{ marginRight: 10, marginBottom: 10 }}
                  variant="rect"
                  animation="wave"
                  height={28}
                  width={'100%'}
                  // width={getRandomArbitrary(45, 85)}
                />
                {/* {Array.from(Array(6), (_, i) => (
                  <Skeleton
                    key={i}
                    style={{ marginRight: 10, marginBottom: 10 }}
                    variant="rect"
                    animation="wave"
                    height={28}
                    // width={getRandomArbitrary(45, 85)}
                  />
                ))} */}
              </div>
            </div>
            <div className="form-content-wrap">
              <h3>Forms</h3>
              <div className="tag-wrap">
                <Skeleton
                  key={i}
                  style={{ marginRight: 10, marginBottom: 10 }}
                  variant="rect"
                  animation="wave"
                  height={28}
                  width={'100%'}
                  // width={getRandomArbitrary(45, 85)}
                />
                {/* {Array.from(Array(6), (_, i) => (
                  <Skeleton
                    key={i}
                    style={{ marginRight: 10, marginBottom: 10 }}
                    variant="rect"
                    animation="wave"
                    height={28}
                    width={100}
                    // width={getRandomArbitrary(45, 85)}
                  />
                ))} */}
              </div>
            </div>
          </div>
        </FormRow>
      ))}
    </Grid>
  );
}

export default MetalTabSkelton;
