import React, { useEffect, useState } from 'react';
import { wp } from '../../configs/MetalConfig';

import Main from '../../components/layouts/main';

import './form.scss';

// http://localhost:8080/wp-json/wp/v2/alloy_steel_specs?slug=ams-6359
function Form({ match, formObj }) {
  // const currentMetal = getCurrentMetal(match.params.metal);
  const formSlug = match.params.form;
  const [state, setState] = useState({
    post: null,
  });

  useEffect(() => {
    wp.form()
      .param('slug', formSlug)
      .then(data =>
        setState({
          post: data[0],
        }),
      );
  }, [formSlug]);

  return (
    <Main
      title={state.post && state.post.title ? state.post.title.rendered : null}
      layout="sidebar"
      postData={state.post}
      formObj={formObj}
    />
  );
}

export default Form;
