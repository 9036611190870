import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: 'rgb(55, 47, 104)',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#fff',
    },
    contained: 'rgb(55, 47, 104)',
    text: {
      primary: '#2f2f2f',
    },
  },
  typography: {
    fontFamily: ['Rajdhani', 'sans-serif'].join(','),
    button: ['Source Sans Pro', 'sans-serif'].join(','),
    body1: {
      color: '#2f2f2f',
    },
  },
});

export default theme;
