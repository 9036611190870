import React, { useEffect, useState } from 'react';
import WPAPI from 'wpapi';

import Main from '../../components/layouts/main';
import { envUrl } from '../../configs/MetalConfig';

import withLayoutController from '../../HOCs/withLayoutController';

const MainWithLayoutController = withLayoutController(Main);

const local = `${envUrl}wp-json/`;
export const wp = new WPAPI({ endpoint: local });
const namespace = 'wp/v2';

wp.glossary = wp.registerRoute(namespace, '/glossary/(?P<slug>[^/]+)');

function GlossarySingle({ match, formObj, openModal }) {
  const [state, setState] = useState({
    post: null,
  });
  const glossarySlug = match.params.glossary;
  const itemSlug = match.params.item;

  useEffect(() => {
    setState({ post: null });
    const fetchData = async () => {
      try {
        let data;
        if (itemSlug) {
          // Manually construct the endpoint URL for dynamic pillars
          const pillar = glossarySlug;
          const endpoint = `${local}${namespace}/${pillar}?_embed=true&slug=${itemSlug}`;
          const response = await fetch(endpoint);
          data = await response.json();
        } else {
          data = await wp.glossary().param('slug', glossarySlug).embed();
        }
        setState({ post: data[0] });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [glossarySlug, itemSlug]);

  return (
    <MainWithLayoutController
      title={state.post && state.post.title ? state.post.title.rendered : null}
      postData={state.post}
      formObj={formObj}
      openModal={openModal}
    />
  );
}

export default GlossarySingle;
