import React from 'react';

import { Paper, Box, Grid } from '@material-ui/core';

import PostSkeleton from '../skeletons/post-skeleton/post-skeleton';
import ContactForm from '../contact-form/contact-form';
import Banner from '../banner/banner';
import HelmetWrap from '../helmet-wrap/helmet-wrap';
import FlexibleLayout from '../../pages/flexible-layout/flexible-layout';

import './main.scss';

const classes = {
  paper: {
    overflow: 'hidden',
    borderRadius: 8,
  },
  innerContent: {
    textAlign: 'center',
  },
  sidebar: { maxWidth: 1350, margin: 'auto', padding: '20px 0 80px' },
};

function Main({
  title,
  layout,
  postData,
  children,
  formObj,
  layouts,
  classNames = [],
  meta = [],
  description,
  seoTitle,
  ...props
}) {
  const titleClass = title ? title.replace(' ', '') : '';
  const className =
    titleClass && layout
      ? `${titleClass}-page ${titleClass} page-${layout} page-${titleClass}-${layout} ${classNames.join(
          ' ',
        )}`
      : '';

  const imageEmbed =
    postData && postData._embedded && postData._embedded['wp:featuredmedia']
      ? postData._embedded['wp:featuredmedia'][0].source_url
      : null;

  let layoutType;

  // Filter out any existing meta description
  const filteredMeta = meta.filter(item => item.name !== 'description');

  // Add the new meta description
  if (description) {
    filteredMeta.push({ name: 'description', content: description });
  }

  let seoTitleValue = seoTitle ? seoTitle : title;

  switch (layout) {
    case 'title-bar':
      layoutType = (
        <div className={className}>
          <Banner title={title} />
          <div className="body-content" style={{ padding: '0 30px' }}>
            {children}
          </div>
        </div>
      );
      break;
    case 'sidebar':
      layoutType = (
        <>
          <Banner title={title} img={imageEmbed} />
          <div className={className} style={classes.sidebar}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={8}>
                <Paper elevation={0}>
                  <Box p={3}>
                    {postData ? (
                      <>
                        {postData.type !== 'page' ||
                        postData.type !== 'post' ? null : (
                          <h2>Overview</h2>
                        )}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: postData.content.rendered,
                          }}
                        />
                        {postData.acf.chemistry && (
                          <>
                            <h2>Chemistry</h2>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: postData.acf.chemistry,
                              }}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <PostSkeleton />
                    )}
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Paper elevation={0} style={classes.paper}>
                  <Box p={0}>
                    <ContactForm {...formObj} />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </>
      );
      break;
    case 'flexibleLayout':
      layoutType = (
        <div className={className}>
          <Banner title={title} img={imageEmbed} />
          <FlexibleLayout layouts={layouts} {...props} />
        </div>
      );
      break;
    case 'flexibleLayout-with-sidebar':
      layoutType = (
        <>
          <Banner title={title} img={imageEmbed} />
          <div className={className} style={classes.sidebar}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={9}>
                <Box p={0}>
                  <FlexibleLayout layouts={layouts} {...props} />
                </Box>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Paper elevation={0} style={classes.paper}>
                  <Box p={0}>
                    <ContactForm {...formObj} />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </>
      );
      break;
    default:
      layoutType = <div className={className}>{children}</div>;
      break;
  }

  return (
    <>
      <HelmetWrap title2={seoTitleValue} meta={filteredMeta} postData={postData} />
      {layoutType}
    </>
  );
}

export default Main;
