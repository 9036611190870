import React, { useEffect, useState } from 'react';
import WPAPI from 'wpapi';

import Main from '../../components/layouts/main';
import { envUrl } from '../../configs/MetalConfig';

import withLayoutController from '../../HOCs/withLayoutController';

const MainWithLayoutController = withLayoutController(Main);

const local = `${envUrl}wp-json/`;
export const wp = new WPAPI({ endpoint: local });
const namespace = 'wp/v2';

wp.service = wp.registerRoute(namespace, '/um_services/(?P<id>)');

function ServicesSingle({ match, formObj, openModal }) {
  const [state, setState] = useState({
    post: null,
  });
  const serviceSlug = match.params.service;

  useEffect(() => {
    setState({ post: null });
    wp.service()
      .param('slug', serviceSlug)
      .param('_', '2024-09-07') // Expire cache for todays date
      .embed()
      .then(data =>
        setState({
          post: data[0],
        }),
      );
  }, [serviceSlug]);

  return (
    <MainWithLayoutController
      title={state.post && state.post.title ? state.post.title.rendered : null}
      postData={state.post}
      formObj={formObj}
      openModal={openModal}
    />
  );
}

export default ServicesSingle;
