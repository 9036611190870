import React from 'react';

import Section from '../../section/section';
import Slider from '../../slider/slider';
import Image from '../../image/image';

import { getFeaturedImage } from '../../../utils/utils';

function ImageSlider({ className, index, gallery = [], numberOfSlides }) {
  return (
    <Section className={className} index={index} style={{ padding: '60px 0' }}>
      <div className="section-wrap">
        <h2>Customers</h2>
        {gallery.length > 0 && (
          <Slider numberOfSlides={numberOfSlides}>
            {gallery.map((imgObj, i) => (
              <div key={i} style={{ display: 'flex', padding: 10 }}>
                <div
                  className="image-wrap"
                  style={{
                    display: 'flex',
                    padding: '0 10px',
                    background: 'white',
                    width: '100%',
                  }}
                >
                  <Image
                    style={{ width: '100%', margin: 'auto' }}
                    alt={imgObj.alt}
                    src={getFeaturedImage(imgObj, 'medium')}
                  />
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </Section>
  );
}

export default ImageSlider;
