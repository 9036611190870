import React, { useState } from 'react';

import { TextField, MenuItem } from '@material-ui/core';

import CardBody from '../card-body/card-body';

function WeightConversion() {
  const [state, setState] = useState({
    lengthFrom: 'milligrams',
    lengthTo: 'centigrams',
    value: null,
    inputValue: null,
  });

  let total = 0;

  const conversions = {
    milligrams: {
      from: () => state.inputValue * 0.001,
      to: grams => grams / 0.001,
      name: 'Milligrams',
    },
    centigrams: {
      from: () => state.inputValue * 0.01,
      to: grams => grams / 0.01,
      name: 'Centigrams',
    },
    decigrams: {
      from: () => state.inputValue * 0.1,
      to: grams => grams / 0.1,
      name: 'Decigrams',
    },
    grams: {
      from: () => state.inputValue * 1,
      to: grams => grams / 1,
      name: 'Grams',
    },
    dekagrams: {
      from: () => state.inputValue * 10,
      to: grams => grams / 10,
      name: 'Dekagrams',
    },
    hectograms: {
      from: () => state.inputValue * 100,
      to: grams => grams / 100,
      name: 'Hectograms',
    },
    kilograms: {
      from: () => state.inputValue * 1000,
      to: grams => grams / 1000,
      name: 'Kilograms',
    },
    ounces: {
      from: () => state.inputValue * 28.3495231,
      to: grams => grams / 28.3495231,
      name: 'Ounces',
    },
    pound: {
      from: () => state.inputValue * 453.59237,
      to: grams => grams / 453.59237,
      name: 'Pound',
    },
    metricTons: {
      from: () => state.inputValue / 1000000,
      to: grams => grams * 1000000,
      name: 'Metric Tons',
    },
    netTon: {
      from: () => state.inputValue * 907184.74,
      to: grams => grams / 907184.74,
      name: 'Net Ton',
    },
    shortTon: {
      from: () => state.inputValue * 907184.74,
      to: grams => grams / 907184.74,
      name: 'Decimeters',
    },
  };

  const lengthConverter = (e, from) => {
    const lengthDir = from === 'from' ? 'lengthFrom' : 'lengthTo';
    const type = e.target.value;

    setState(s => ({
      ...s,
      [lengthDir]: type,
    }));
  };

  const handleTextChange = e => {
    const inputValue = e.currentTarget.value;
    setState(s => ({ ...s, inputValue }));
  };

  if (state.lengthFrom && state.lengthTo) {
    const grams = conversions[state.lengthFrom].from();
    total = conversions[state.lengthTo].to(grams);
  }

  return (
    <>
      <CardBody title="Weight Converter">
        <TextField
          id="length-input"
          type="text"
          onChange={handleTextChange}
          value={state.inputValue}
          label="Weight"
          variant="filled"
          style={{ marginRight: 10 }}
        />
        <TextField
          id="length-from-types"
          onChange={e => lengthConverter(e, 'from')}
          value={state.lengthFrom}
          select
          name="from-type"
          label="From"
          variant="filled"
          style={{ background: 'white', marginRight: 10, minWidth: 110 }}
        >
          {Object.keys(conversions).map((conversion, i) => (
            <MenuItem key={i} value={conversion}>
              {conversions[conversion].name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="length-to-types"
          onChange={e => lengthConverter(e, 'to')}
          value={state.lengthTo}
          select
          name="to-type"
          label="To"
          variant="filled"
          style={{ background: 'white', marginRight: 10, minWidth: 110 }}
        >
          {Object.keys(conversions).map((conversion, i) => (
            <MenuItem key={i} value={conversion}>
              {conversions[conversion].name}
            </MenuItem>
          ))}
        </TextField>
        {total !== 0 && (
          <div id="length-output">
            {state.inputValue} {conversions[state.lengthFrom].name} = {total}{' '}
            {conversions[state.lengthTo].name}
          </div>
        )}
      </CardBody>
    </>
  );
}

export default WeightConversion;
