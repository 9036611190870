import React from 'react';

import Section from '../../section/section';
import { Skeleton } from '@material-ui/lab';

function SectionSkeleton(index) {
  return (
    <Section index={index}>
      <Skeleton height={50} />
      <Skeleton height={50} />
      <Skeleton height={50} />
    </Section>
  );
}

export default SectionSkeleton;
