import React from 'react';

import { Button as MaterialButton } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: '14px 40px',
      color: 'white',
      background: theme.palette.contained,
      fontWeight: 600,
      textTransform: 'uppercase',
      fontSize: 15,
      '&:hover': {
        background: 'white',
        color: 'black',
      },
    },
  };
});

function Button({ children, style = {}, ...otherProps }) {
  const classes = useStyles();

  return (
    <MaterialButton className={classes.root} style={style} {...otherProps}>
      {children}
    </MaterialButton>
  );
}

export default Button;
