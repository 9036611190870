export const metalStuffs = {
  alloySteel: [
    { name: 4130, value: 0.282 },
    { name: 4135, value: 0.282 },
    { name: 4140, value: 0.282 },
    { name: 4330, value: 0.283 },
    { name: 4340, value: 0.284 },
    { name: 9310, value: 0.284 },
  ],
  aluminum: [
    { name: 1100, value: 0.098 },
    { name: 2011, value: 0.102 },
    { name: 2024, value: 0.101 },
    { name: 2219, value: 0.103 },
    { name: 3003, value: 0.099 },
    { name: 5052, value: 0.097 },
    { name: 5083, value: 0.096 },
    { name: 5086, value: 0.096 },
    { name: 6013, value: 0.098 },
    { name: 6061, value: 0.098 },
    { name: 6063, value: 0.097 },
    { name: 6262, value: 0.098 },
    { name: 7050, value: 0.102 },
    { name: 7075, value: 0.101 },
    { name: 7150, value: 0.102 },
    { name: 'Alca Max', value: 0.1 },
    { name: 'Alca Plus', value: 0.101 },
    { name: 'MIC-6', value: 0.101 },
    { name: 'QC-7', value: 0.101 },
  ],
  brass: [
    { name: '240 - Low Brass', value: 0.313 },
    { name: '260 - Cartridge Brass', value: 0.308 },
    { name: '353 - Leaded Brass', value: 0.306 },
    { name: '360 - Brass', value: 0.307 },
    { name: '365 - Brass', value: 0.304 },
    { name: '380 - Brass', value: 0.306 },
    { name: '385 - Brass', value: 0.306 },
    { name: '464 - Brass', value: 0.304 },
  ],
  bronze: [
    { name: '18 - Aluminum Bronze', value: 0.269 },
    { name: '21 - Aluminum Bronze', value: 0.26 },
    { name: '314 - Commercial Bronze', value: 0.306 },
    { name: '425 - Am Bronze', value: 0.316 },
    { name: '485 - Naval Bronze', value: 0.305 },
    { name: '510 - Phos Bronze', value: 0.32 },
    { name: '524 - Phos Bronze', value: 0.317 },
    { name: '532 - Phos Bronze', value: 0.323 },
    { name: '534 - Phos Bronze', value: 0.322 },
    { name: '544 - Phos Bronze', value: 0.32 },
    { name: '613 - Al Bronze', value: 0.287 },
    { name: '614 - Al Bronze', value: 0.285 },
    { name: '623 - Al Bronze', value: 0.276 },
    { name: '624 - Al Bronze', value: 0.269 },
    { name: '625 - Al Bronze', value: 0.26 },
    { name: '630 - Ni-Al Bronze', value: 0.274 },
    { name: '642 - Al-Si Bronze', value: 0.278 },
    { name: '863 - Manganese Bronze', value: 0.278 },
    { name: '932 - Bearing Bronze', value: 0.322 },
    { name: '954 - Al Bronze', value: 0.269 },
    { name: 'SAE841 - Powdered Bronze', value: 0.236 },
    { name: 'SAE843 - Powdered Bronze', value: 0.216 },
    { name: 'Oil-Lite - Bronze', value: 0.236 },
  ],
  copper: [
    { name: 101, value: 0.323 },
    { name: 102, value: 0.323 },
    { name: 103, value: 0.323 },
    { name: 110, value: 0.322 },
    { name: 122, value: 0.323 },
    { name: 145, value: 0.323 },
  ],
  copperAlloy: [
    { name: '145 - Tellurium Copper', value: 0.323 },
    { name: '194 - Iron Copper', value: 0.322 },
    { name: '195 - Iron Copper', value: 0.322 },
    { name: '172 - Beryllium Copper', value: 0.298 },
    { name: '182 - Class 2', value: 0.321 },
    { name: '655 - Copper-Silicon', value: 0.308 },
    { name: '706 - Copper-Nickel', value: 0.323 },
    { name: '715 - Nickel Silver', value: 0.323 },
    { name: '725 - Nickel Silver', value: 0.321 },
    { name: '735 - Nickel Silver', value: 0.319 },
    { name: '752 - Nickel Silver', value: 0.316 },
    { name: '762 - Nickel Silver', value: 0.31 },
    { name: '770 - Nickel Silver', value: 0.314 },
    { name: '1751 - Class 3', value: 0.317 },
    { name: '1758 - Copper-Nickel', value: 0.323 },
    { name: 'Moldmax - Be/Cu', value: 0.302 },
    { name: 'Protherm - Be/Cu', value: 0.319 },
  ],
  magnesium: [{ name: 'AZ31B', value: 0.064 }],
  nickel: [
    { name: 201, value: 0.321 },
    { name: 400, value: 0.319 },
    { name: 210, value: 0.32 },
    { name: 220, value: 0.318 },
    { name: 230, value: 0.316 },
  ],
  plastic: [
    { name: 'ABS', value: 0.0394 },
    { name: 'Acetal', value: 0.0514 },
    { name: 'Acrylic', value: 0.0434 },
    { name: 'Acrylic', value: 0.0375 },
    { name: 'Benelex', value: 0.0496 },
    { name: 'Biton', value: 0.0673 },
    { name: 'Buna-N', value: 0.0507 },
    { name: 'Butyl', value: 0.0427 },
    { name: 'CAB', value: 0.0431 },
    { name: 'Cellulose Acetate', value: 0.047 },
    { name: 'CPVC', value: 0.0565 },
    { name: 'Delrin', value: 0.0514 },
    { name: 'Delrin AF', value: 0.0557 },
    { name: 'Duraboard', value: 0.0181 },
    { name: 'E-CTFE', value: 0.0608 },
    { name: 'EPDM', value: 0.0427 },
    { name: 'Ertacetel H', value: 0.0514 },
    { name: 'Ertalyte', value: 0.0503 },
    { name: 'Fiber Sheet', value: 0.0503 },
    { name: 'Flourosilicone', value: 0.0507 },
    { name: 'Glastherm HT', value: 0.0615 },
    { name: 'Glastherm S', value: 0.0615 },
    { name: 'GPO-1', value: 0.0717 },
    { name: 'GPO-2', value: 0.0651 },
    { name: 'GPO-3', value: 0.0659 },
    { name: 'Gum Rubber', value: 0.0333 },
    { name: 'Halar', value: 0.0608 },
    { name: 'Haysite EHC-P', value: 0.0724 },
    { name: 'Haysite HST-11-P', value: 0.0586 },
    { name: 'Hypalan', value: 0.0427 },
    { name: 'ICE', value: 0.0326 },
    { name: 'Kapton', value: 0.0514 },
    { name: 'Kydex', value: 0.0489 },
    { name: 'Kynar', value: 0.0633 },
    { name: 'Lead', value: 0.4104 },
    { name: 'Lexan', value: 0.0434 },
    { name: 'Marinete P', value: 0.0326 },
    { name: 'Mylar', value: 0.0503 },
    { name: 'Natural Rubber', value: 0.0333 },
    { name: 'Neoprene', value: 0.047 },
    { name: 'Nitrile', value: 0.0362 },
    { name: 'Noryl', value: 0.0384 },
    { name: 'Nylatron GS', value: 0.042 },
    { name: 'Nylon 6/6', value: 0.0413 },
    { name: 'Peek', value: 0.0478 },
    { name: 'PET', value: 0.0503 },
    { name: 'PETG', value: 0.046 },
    { name: 'Phenolic CE', value: 0.0413 },
    { name: 'Phenolic G-10', value: 0.0659 },
    { name: 'Phenolic G-11', value: 0.0659 },
    { name: 'Phenolic G-3', value: 0.0651 },
    { name: 'Phenolic G-5', value: 0.0706 },
    { name: 'Phenolic G-7', value: 0.067 },
    { name: 'Phenolic G-9', value: 0.0688 },
    { name: 'Phenolic LE', value: 0.0413 },
    { name: 'Phenolic N-1', value: 0.0427 },
    { name: 'Phenolic XX', value: 0.0485 },
    { name: 'Phenyleneoxide', value: 0.0384 },
    { name: 'Polyamide', value: 0.0413 },
    { name: 'Polyamide-Imide', value: 0.0514 },
    { name: 'Polybenzimidazote', value: 0.047 },
    { name: 'Polycarbonate', value: 0.0434 },
    { name: 'Polyethelene LD', value: 0.0344 },
    { name: 'Polyetherimide', value: 0.046 },
    { name: 'Polymethylpentene', value: 0.03 },
    { name: 'Polymides Grafite', value: 0.0561 },
    { name: 'PolyPro Copolymer', value: 0.0326 },
    { name: 'Polypro HomoPolymer', value: 0.0329 },
    { name: 'Polystyrene', value: 0.038 },
    { name: 'Polysulfone', value: 0.0449 },
    { name: 'PPO', value: 0.0384 },
    { name: 'PTFE', value: 0.0778 },
    { name: 'PVC Type I', value: 0.051 },
    { name: 'PVC Type II', value: 0.0489 },
    { name: 'Red Sheet', value: 0.0626 },
    { name: 'SBR', value: 0.034 },
    { name: 'SG-200', value: 0.0615 },
    { name: 'Silicone', value: 0.0507 },
    { name: 'Silver', value: 0.38 },
    { name: 'Styrene', value: 0.038 },
    { name: 'Torlon', value: 0.0514 },
    { name: 'TPX', value: 0.03 },
    { name: 'Transite', value: 0.0507 },
    { name: 'Transite', value: 0.0615 },
    { name: 'UHMW', value: 0.0344 },
    { name: 'Ultem', value: 0.046 },
    { name: 'Urethane', value: 0.047 },
    { name: 'Vespel', value: 0.0518 },
    { name: 'Vinyl', value: 0.047 },
    { name: 'Vulcanized Fiber', value: 0.0503 },
    { name: 'X Link Styrene', value: 0.038 },
  ],
  stainless: [
    { name: 301, value: 0.287 },
    { name: 302, value: 0.287 },
    { name: 303, value: 0.287 },
    { name: 304, value: 0.287 },
    { name: 305, value: 0.287 },
    { name: 316, value: 0.286 },
    { name: 321, value: 0.285 },
    { name: 409, value: 0.278 },
    { name: 410, value: 0.278 },
    { name: 420, value: 0.28 },
    { name: 430, value: 0.278 },
    { name: '15-5', value: 0.287 },
    { name: '17-4', value: 0.29 },
  ],
  steel: [
    { name: 1010, value: 0.283 },
    { name: 1012, value: 0.283 },
    { name: 1015, value: 0.283 },
    { name: 1018, value: 0.283 },
    { name: 1541, value: 0.283 },
    { name: 4140, value: 0.283 },
    { name: 'A-36', value: 0.283 },
  ],
  titanium: [
    { name: '6Al-4V', value: 0.163 },
    { name: 'Grade 2', value: 0.163 },
    { name: 'Grade 4', value: 0.163 },
    { name: 'Grade 5', value: 0.163 },
  ],
  toolSteel: [{ name: 'S-7', value: 0.283 }],
  zinc: [
    { name: 190, value: 0.259 },
    { name: 310, value: 0.258 },
    { name: 320, value: 0.258 },
    { name: 500, value: 0.259 },
    { name: 700, value: 0.259 },
    { name: 720, value: 0.259 },
    { name: 750, value: 0.259 },
  ],
};

export const shapes = {
  round: {
    calc: ({ dim1, density, dim2 }) =>
      dim1 * dim1 * 9.42 * density * (dim2 / 12),
    labels: ['Diameter', 'Length'],
  },
  square: {
    calc: ({ dim1, density, dim2 }) => dim1 * dim1 * density * dim2,
    labels: ['Width', 'Length'],
  },
  hex: {
    calc: ({ dim1, density, dim2 }) =>
      dim1 * dim1 * density * 10.4 * (dim2 / 12),
    labels: ['Diameter', 'Length'],
  },
  flat: {
    calc: ({ dim1, dim2, density, dim3 }) => dim1 * dim2 * density * dim3,
    labels: ['Gauge', 'Width', 'Length'],
  },
  sheet: {
    calc: ({ dim1, dim2, density, dim3 }) => dim1 * dim2 * density * dim3,
    labels: ['Gauge', 'Width', 'Length'],
  },
  rectangle: {
    calc: ({ dim1, dim2, density, dim3 }) => dim1 * dim2 * density * dim3,
    labels: ['Gauge', 'Width', 'Length'],
  },
  plate: {
    calc: ({ dim1, dim2, density, dim3 }) => dim1 * dim2 * density * dim3,
    labels: ['Gauge', 'Width', 'Length'],
  },
  tube: {
    calc: ({ dim1, dim2, density, dim3 }) =>
      (dim1 - dim2) * dim2 * density * 37.7 * (dim3 / 12),
    labels: ['Outer Diameter', 'Wall', 'Length'],
  },
  pipe: {
    calc: ({ dim1, dim2, density, dim3 }) =>
      (dim1 - dim2) * dim2 * density * 37.7 * (dim3 / 12),
    labels: ['Outer Diameter', 'Wall', 'Length'],
  },
  ring: {
    calc: ({ dim1, dim2, density, dim3 }) =>
      (dim2 - (dim2 - dim3) / 2) *
      ((dim2 - dim3) / 2) *
      density *
      37.7 *
      (dim1 / 12),
    labels: ['Thickness', 'Outer Diameter', 'Inner Diameter'],
  },
  rectangleTube: {
    calc: ({ dim1, dim2, density, dim3, dim4 }) =>
      (dim1 * dim2 * 12 * density -
        (dim1 - dim3 * 2) * (dim2 - dim3 * 2) * 12 * density) *
      (dim4 / 12),
    labels: ['Height (A)', 'Width (B)', 'Wall (C)', 'Length'],
  },
  squareTube: {
    calc: ({ dim1, dim2, density, dim3 }) =>
      37.7 * density * dim2 * (dim1 - dim2) * 1.27 * (dim3 / 12),
    labels: ['Outer Diameter (A)', 'Wall (B)', 'Length'],
  },
};

function convertToInch(value, type) {
  if (type === 'ft') value *= 12;
  if (type === 'yd') value *= 36;
  if (type === 'cm') value /= 2.54;
  if (type === 'm') value = (value * 100) / 2.54;
  if (type === 'mm') value = value / 10 / 2.54;
  return value;
}

export function calculateWeight({
  dim1,
  dim2,
  dim3,
  dim4,
  pieces,
  alloy,
  shape,
}) {
  const density = alloy.value;
  const dim1Converted = convertToInch(dim1.input, dim1.units);
  const dim2Converted = convertToInch(dim2.input, dim2.units);
  const dim3Converted = convertToInch(dim3.input, dim3.units);
  const dim4Converted = convertToInch(dim4.input, dim4.units);

  const myPcWeight = shapes[shape].calc({
    dim1: dim1Converted,
    dim2: dim2Converted,
    dim3: dim3Converted,
    dim4: dim4Converted,
    density,
  });

  const totalWeight = myPcWeight * pieces;

  return [myPcWeight, totalWeight];
}
