import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render, hydrate } from 'react-dom';
import './styles/style.scss';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import App from './components/App';
import theme from './themes';
import * as serviceWorker from './serviceWorker';
import ReactGA from './analytics';

ReactGA.initialize('G-RS36X99VR1');

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>,
    rootElement,
  );
} else {
  render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>,
    rootElement,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
