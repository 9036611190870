import React from 'react';
import { Grid, Box, IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { withStyles } from '@material-ui/core/styles';

import Image from '../../image/image';

import Section from '../../section/section';

function SideContentSection({ icons = [], sectionTitle, image, classes }) {
  return (
    <Section className="section-3" style={{ padding: '40px 0 100px' }}>
      <div className="section-wrap">
        <h2>{sectionTitle}</h2>
        <Grid container spacing={5}>
          <Grid item xs={12} md={12} container alignItems="center">
            <Image
              alt={image.alt}
              style={{
                width: '100%',
                boxShadow: 'rgba(0, 0, 0, 0.38) 1px 4px 24px',
                maxWidth: 550,
                borderRadius: 8,
                margin: 'auto',
              }}
              src={image.url}
            />
          </Grid>
          <Grid item xs={12} md={12} container alignItems="center">
            <Grid container spacing={2}>
              {icons.map(({ title, description, file }) => (
                <Grid item xs={12} md={4}>
                  <Box
                    style={{
                      // background: '#efefef',
                      background: 'white',
                      padding: 20,
                      borderRadius: 8,
                    }}
                  >
                    <h3 style={{ textAlign: 'center', margin: 0 }}>{title}</h3>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>
                        <IconButton
                          className={classes.icon}
                          href={file}
                          color="primary"
                          target="_blank"
                        >
                          <GetAppIcon />
                        </IconButton>
                        {description}
                      </div>
                    </div>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Section>
  );
}

const styles = theme => ({
  icon: {
    background: '#efefef',
    padding: 6,
    marginRight: 4,
  },
});

export default withStyles(styles)(SideContentSection);
