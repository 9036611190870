import React from 'react';

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Section from '../../section/section';
import Card from '../../card/card';

import { getFeaturedImage } from '../../../utils/utils';

function CardSection({ cards, index, sectionContent, buttonComponent, classes }) {
  const { sectionTitle, sectionDescription } = sectionContent;

  return (
    <Section
      index={index}
      className="section-3"
      style={{ padding: '40px 0 100px' }}
    >
      <div className="section-wrap">
        {sectionTitle && (
          <h2 className={classes.sectionTitle}>{sectionTitle}</h2>
        )}
        {sectionDescription && (
          <div
            className={classes.sectionDescription}
            dangerouslySetInnerHTML={{ __html: sectionDescription }}
          />
        )}
        <Grid container spacing={3}>
          {cards.map(({ sectionContent, cardImage, buttonComponent }, i) => (
            buttonComponent.buttonLink ? (
              <a 
                key={i} 
                href={buttonComponent.buttonLink} 
                className="MuiGrid-root card MuiGrid-container MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 MuiGrid-grid-md-4"
              >
                <Card
                  img={getFeaturedImage(cardImage, 'medium')}
                  title={sectionContent.sectionTitle}
                  description={sectionContent.sectionDescription}
                  skeletons={null}
                />
              </a>
            ) : (
                <Card
                  key={i}
                  xs={12}
                  sm={6}
                  md={4}
                  img={getFeaturedImage(cardImage, 'medium')}
                  title={sectionContent.sectionTitle}
                  description={sectionContent.sectionDescription}
                  skeletons={null}
                />
            )
          ))}
        </Grid>
      </div>
    </Section>
  );
}

const styles = theme => ({
  sectionTitle: {
    marginBottom: 5,
  },
  sectionDescription: {
    '& p': {
      margin: '0 0 20px',
      textAlign: 'center',
    },
  },
});

export default withStyles(styles)(CardSection);
