import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import { wp, getCurrentMetal } from '../../configs/MetalConfig';
import { cache } from '../../utils/utils';

import Main from '../../components/layouts/main';

import './grade.scss';

function useWPApi(options = {}, deps = []) {
  const [state, setState] = useState({
    post: null,
    isLoading: false,
    error: null,
  });

  useEffect(() => {
    const cachedData = cache.get(options.cacheKey);
    if (cachedData) {
      setState(s => ({
        ...s,
        post: cachedData,
      }));
    } else {
      setState(s => ({ ...s, isLoading: true }));
      wp[options.apiType]()
        .param('slug', options.slug)
        .then(data => {
          if (data && data.length > 0) {
            cache.save(options.cacheKey, data[0]);
            setState(s => ({
              ...s,
              post: data[0],
              isLoading: false,
            }));
          } else {
            setState(s => ({
              ...s,
              post: null,
              isLoading: false,
              error: 'Not Found',
            }));
          }
        })
        .catch(error => {
          setState(s => ({
            ...s,
            post: null,
            isLoading: false,
            error: error.message,
          }));
        });
    }
  }, deps);

  return [state.post, state.isLoading, state.error];
}

const Grade = ({ match, formObj }) => {
  const currentMetal = getCurrentMetal(match.params.metal);
  const gradeSlug = match.params.grade;

  const apiOptions = {
    apiType: currentMetal.shortName,
    slug: gradeSlug,
    cacheKey: gradeSlug,
  };
  const [post, isLoading, error] = useWPApi(apiOptions, [currentMetal.shortName, gradeSlug]);

  if (error) {
    return <Redirect to="/404" />;
  }

  return (
    <div className="grade-page">
      <Main
        title={post && post.title ? post.title.rendered : null}
        layout="sidebar"
        postData={post}
        formObj={formObj}
      />
    </div>
  );
};

export default Grade;