import React, { useEffect, useState } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Main from '../../components/layouts/main';
import GlossaryCollection from '../../components/sections/glossary-collection/glossary-collection';

import { wp } from '../../configs/MetalConfig';
import { cache } from '../../utils/utils';

const namespace = 'wp/v2';
wp.glossary = wp.registerRoute(namespace, '/glossary/');

const classes = {
  pageWrap: { maxWidth: 1400, margin: 'auto' },
};

function Glossary({ match, openModal }) {
  const [state, setState] = useState({
    posts: cache.get('glossary') || [],
    images: {},
  });

  const getData = async () => {
    if (!state.posts || state.posts.length === 0) {
      const posts = await wp.glossary().embed();
      cache.save('glossary', posts);
      setState(s => ({ ...s, posts }));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Main layout="title-bar" title="Glossary">
      <div style={classes.pageWrap}>
        <GlossaryCollection
          glossaryCollection={state.posts}
          index={3}
          className={['section-3', classes.postCollection].join(' ')}
          onClick={openModal}
        />
      </div>
    </Main>
  );
}

const styles = theme => ({
  postCollection: { padding: '40px 0 100px' },
});

export default React.memo(withStyles(styles)(Glossary));
