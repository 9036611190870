import React from 'react';

function withLayoutController(Component) {
  return function InnerComponent({ postData, ...props }) {
    let layout;

    if (postData && postData.template === 'flexibleLayout.php') {
      layout = postData.template.replace('.php', '');

      if (postData.acf && postData.acf.sidebar) {
        layout += '-with-sidebar';
      }
    }

    return (
      <Component
        {...props}
        layout={layout || 'sidebar'}
        layouts={
          postData && postData.acf.flexibleLayoutDanzerscript
            ? postData.acf.flexibleLayoutDanzerscript
            : []
        }
        postData={postData}
      />
    );
  };
}

export default withLayoutController;
