import React, { useState, useEffect } from 'react';

import CardBody from '../card-body/card-body';

import { TextField } from '@material-ui/core';

function calculateMinimumDiameter(desiredWidth, desiredHeight) {
  const kerf = 0.08;
  const tolerance = 0.125 + kerf;

  try {
    return Math.sqrt(
      Math.pow(desiredWidth * 1 + tolerance, 2) +
        Math.pow(desiredHeight * 1 + tolerance, 2),
    );
  } catch (e) {
    return null;
  }
}

function RoundToSquare() {
  const [minimumDiameter, setMinimumDiameter] = useState(null);
  const [desiredHeight, setDesiredHeight] = useState(null);
  const [desiredWidth, setDesiredWidth] = useState(null);

  useEffect(() => {
    if (desiredWidth && desiredHeight) {
      const newMinimumDiameter = calculateMinimumDiameter(
        desiredWidth,
        desiredHeight,
      );
      setMinimumDiameter(newMinimumDiameter);
    }
  }, [desiredWidth, desiredHeight]);

  return (
    <>
      <CardBody title="Round-to-Square Calculator">
        <TextField
          id="desired-width"
          type="number"
          onChange={e => {
            const newDesiredWidth = e.target.value;
            setDesiredWidth(newDesiredWidth);
          }}
          label="Desired Width"
          multiline
          variant="filled"
          style={{ marginRight: 10 }}
        />
        <TextField
          id="desired-height"
          type="number"
          onChange={e => {
            const newDesiredHeight = e.target.value;
            setDesiredHeight(newDesiredHeight);
          }}
          label="Desired Height"
          multiline
          variant="filled"
        />
        {desiredWidth && desiredHeight && (
          <div className="length-output">
            Minimum diameter:
            <span id="minimum-diameter"> {minimumDiameter}</span>
          </div>
        )}
        <div>
          <span style={{ fontSize: 12 }}>
            <i>
              <b>Note:</b> Tolerance of .125” will be automatically added to
              each dimension
            </i>
          </span>
        </div>
      </CardBody>
    </>
  );
}

export default RoundToSquare;
