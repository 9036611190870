import React from 'react';

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ButtonWrap from '../button-wrap/button-wrap';
import Image from '../image/image';

import { getFeaturedImage } from '../../utils/utils';

function SimpleGallery({
  imagesArr = [],
  title,
  description,
  classes,
  openModal,
}) {
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            {imagesArr.map((imageData, i) => (
              <Grid item xs={6} style={{ lineHeight: 0 }}>
                <div
                  key={`image-gallery-${i}`}
                  className={['danzerpress-image-wrap', classes.imgWrap].join(
                    ' ',
                  )}
                >
                  <Image
                    className={classes.img}
                    alt={imageData.alt}
                    src={getFeaturedImage(imageData)}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container item xs={12} md={6} alignContent="center">
          <div style={{ padding: '4%', width: '100%' }}>
            <h2 className={classes.title}>{title}</h2>
            <div
              className={classes.description}
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <div style={{ textAlign: 'center' }}>
              <ButtonWrap onClickLeft={openModal} linkRight={'yeah'} />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const styles = theme => ({
  title: {
    marginBottom: 5,
  },
  description: {
    '& p': {
      textAlign: 'center',
      fontSize: 20,
      margin: '0 0 20px',
    },
  },
  img: {
    boxShadow: theme.shadows[5],
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: 6,
  },
  imgWrap: {
    lineHeight: 0,
    position: 'relative',
    paddingTop: '100%',
  },
});

export default withStyles(styles)(SimpleGallery);
