import React from 'react';
import { useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet';

function HelmetWrap({ postData = {}, title2, meta = [] }) {
  const location = useLocation();

  function contentHandler(metaValue) {
    switch (metaValue.name || metaValue.property) {
      case 'og:url':
        return location.pathname;
      case 'og:description':
        return meta.content || metaValue.content;
      default:
        return metaValue.content;
    }
  }

  if (!postData) {
    postData = {};
  }

  const { title, yoast_title, yoast_meta, yoast_head_json } = postData;
  const titleFromYoast = yoast_head_json?.title;
  const titleType = titleFromYoast || yoast_title || (title ? title.rendered : null) || title2;
  const mapper = yoast_meta || meta;
  const canonicalURL = `https://www.universalmetals.com${location.pathname}`;

  const finalTitle = titleFromYoast
    ? titleFromYoast
    : `${titleType} - Universal Metals`;

  return titleType && mapper ? (
    <Helmet>
      <title>{finalTitle}</title>
      <link rel="canonical" href={canonicalURL} />
      {mapper.map((metaValue, i) => {
        return (
          <meta
            key={`${metaValue.name}-${i}`}
            name={metaValue.name || metaValue.property}
            content={contentHandler(metaValue)}
          />
        );
      })}
    </Helmet>
  ) : null;
}

export default HelmetWrap;
