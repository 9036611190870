import React from 'react';
import { Link } from 'react-router-dom';

import { Chip, Grid } from '@material-ui/core';

import './metal-tab-content.css';

const classes = {
  chip: {
    // padding: '5px 8px',
    margin: '0 10px 10px 0',
    background: '#efefef',
    borderRadius: 4,
    fontSize: 15,
  },
  metalType: {
    fontSize: 18,
  },
};

function FormRow({ children }) {
  return (
    <Grid item xs={12} sm={6} lg={3}>
      <div className="metal-wrapper">{children}</div>
    </Grid>
  );
}

function MetalTabContent({ posts = [], currentMetal = {} }) {
  return (
    <Grid container spacing={4}>
      {posts.map((post, i) => (
        <FormRow key={i}>
          <Link to={`/inventory/${currentMetal.slug}/grade/${post.slug}/`}>
            <h2>{post.title.rendered}</h2>
          </Link>
          <div className="content-wrap">
            <div className="spec-content-wrap" style={{ marginBottom: 20 }}>
              <h3 style={classes.metalType}>Specs</h3>
              <div className="tag-wrap">
                {post.acf['specs-stainless-steel'].map(
                  ({ stainless_steel_spec_links }, i) =>
                    stainless_steel_spec_links && (
                      <Chip
                        key={i}
                        label={stainless_steel_spec_links.post_title}
                        component={Link}
                        to={`/inventory/${currentMetal.slug}/specs/${stainless_steel_spec_links.post_name}/`}
                        clickable
                        style={classes.chip}
                      />
                    ),
                )}
              </div>
            </div>
            <div className="form-content-wrap">
              <h3 style={{ marginBottom: 0, ...classes.metalType }}>Forms</h3>
              <div className="tag-wrap">
                {post.acf['specs-stainless-steel'].map((details, i) => {
                  const { form } = details;

                  let icon;

                  switch (form.post_name) {
                    case 'forged-bar':
                      icon = {
                        height: 12,
                        width: 22,
                        background: '#7e7a96',
                        borderRadius: 12,
                      };
                      break;
                    case 'round-bar':
                      icon = {
                        height: 12,
                        width: 12,
                        background: '#7e7a96',
                        borderRadius: '100%',
                      };
                      break;
                    case 'flat-bar':
                    case 'true-bar':
                      icon = {
                        height: 5,
                        width: 22,
                        background: '#7e7a96',
                        marginLeft: '5px',
                        marginRight: '-6px',
                      };
                      break;
                    default:
                      icon = {
                        height: 12,
                        width: 22,
                        background: '#7e7a96',
                      };
                      break;
                  }

                  return (
                    form && (
                      <Chip
                        key={i}
                        icon={<div className="icon-custom" style={icon} />}
                        label={form.post_title}
                        component={Link}
                        to={`/inventory/${currentMetal.slug}/form/${form.post_name}/`}
                        clickable
                        style={{
                          ...classes.chip,
                          background: 'none',
                          padding: 0,
                        }}
                      />
                    )
                  );
                })}
              </div>
            </div>
          </div>
        </FormRow>
      ))}
    </Grid>
  );
}

export default MetalTabContent;
