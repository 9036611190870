import React, { useState } from 'react';

import { Grid, TextField, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import './sheet-metal-guage-conversion.scss';

const Standard_inch = [
  '0.2391',
  '0.2242',
  '0.2092',
  '0.1943',
  '0.1793',
  '0.1644',
  '0.1495',
  '0.1345',
  '0.1196',
  '0.1046',
  '0.0897',
  '0.0747',
  '0.0673',
  '0.0598',
  '0.0538',
  '0.0478',
  '0.0418',
  '0.0359',
  '0.0329',
  '0.0299',
  '0.0269',
  '0.0239',
  '0.0209',
  '0.0179',
  '0.0164',
  '0.0149',
  '0.0135',
  '0.0120',
  '0.0105',
  '0.0097',
  '0.0090',
  '0.0082',
  '0.0075',
  '0.0067',
];
const Standard_mm = [
  '6.0731',
  '5.6947',
  '5.3137',
  '4.9352',
  '4.5542',
  '4.1758',
  '3.7973',
  '3.4163',
  '3.0378',
  '2.6568',
  '2.2784',
  '1.8974',
  '1.7094',
  '1.5189',
  '1.3665',
  '1.2141',
  '1.0617',
  '0.9119',
  '0.8357',
  '0.7595',
  '0.6833',
  '0.6071',
  '0.5309',
  '0.4547',
  '0.4166',
  '0.3785',
  '0.3429',
  '0.3048',
  '0.2667',
  '0.2464',
  '0.2286',
  '0.2083',
  '0.1905',
  '0.1702',
];

const Galvanized_inch = [
  '---',
  '---',
  '---',
  '---',
  '---',
  '---',
  '0.1532',
  '0.1382',
  '0.1233',
  '0.1084',
  '0.0934',
  '0.0785',
  '0.0710',
  '0.0635',
  '0.0575',
  '0.0516',
  '0.0456',
  '0.0396',
  '0.0366',
  '0.0336',
  '0.0306',
  '0.0276',
  '0.0247',
  '0.0217',
  '0.0202',
  '0.0187',
  '0.0172',
  '0.0157',
  '0.0142',
  '0.0134',
  '---',
  '---',
  '---',
  '---',
];
const Galvanized_mm = [
  '---',
  '---',
  '---',
  '---',
  '---',
  '---',
  '3.8913',
  '3.5103',
  '3.1318',
  '2.7534',
  '2.3724',
  '1.9939',
  '1.8034',
  '1.6129',
  '1.4605',
  '1.3106',
  '1.1582',
  '1.0058',
  '0.9296',
  '0.8534',
  '0.7772',
  '0.7010',
  '0.6274',
  '0.5512',
  '0.5131',
  '0.4750',
  '0.4369',
  '0.3988',
  '0.3607',
  '0.3404',
  '---',
  '---',
  '---',
  '---',
];

const Aluminum_inch = [
  '0.2294',
  '0.2043',
  '0.1819',
  '0.1620',
  '0.1443',
  '0.1285',
  '0.1144',
  '0.1019',
  '0.0907',
  '0.0808',
  '0.0720',
  '0.0641',
  '0.0571',
  '0.0508',
  '0.0453',
  '0.0403',
  '0.0359',
  '0.0320',
  '0.0285',
  '0.0253',
  '0.0226',
  '0.0201',
  '0.0179',
  '0.0159',
  '0.0142',
  '0.0126',
  '0.0113',
  '0.0100',
  '0.0089',
  '0.0080',
  '0.0071',
  '0.0063',
  '0.0056',
  '---',
];
const Aluminum_mm = [
  '5.8268',
  '5.1892',
  '4.6203',
  '4.1148',
  '3.6652',
  '3.2639',
  '2.9058',
  '2.5883',
  '2.3038',
  '2.0523',
  '1.8288',
  '1.6281',
  '1.4503',
  '1.2903',
  '1.1506',
  '1.0236',
  '0.9119',
  '0.8128',
  '0.7239',
  '0.6426',
  '0.5740',
  '0.5105',
  '0.4547',
  '0.4039',
  '0.3607',
  '0.3200',
  '0.2870',
  '0.2540',
  '0.2261',
  '0.2032',
  '0.1803',
  '0.1600',
  '0.1422',
  '---',
];

const useStyles = makeStyles(theme => ({
  root: {
    '& > div': {
      background: 'white',
    },
  },
}));

function SheetMetalGaugeConversion() {
  const [currentIndex, setIndex] = useState(3);
  const classes = useStyles();

  const styles = {
    selectWrap: {
      marginBottom: 10,
    },
    metalInfoWrap: {
      background: 'white',
      borderRadius: 8,
      textAlign: 'center',
      overflow: 'hidden',
    },
    metalInfoContent: {
      padding: 16,
    },
    metalInfoTitle: {
      fontSize: 20,
      marginBottom: 0,
      background: '#362e69',
      color: 'white',
      padding: '10px 7px',
    },
  };

  function changeGauge(e) {
    const newIndex = e.target.value;
    setIndex(newIndex);
  }

  function generateOptions() {
    const items = [];

    for (let i = 3; i <= 36; i++) {
      items.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>,
      );
    }

    return items;
  }

  return (
    <>
      <div style={styles.selectWrap}>
        <TextField
          id="standard-select-currency"
          select
          name="gauge"
          label="Select Gauge"
          value={currentIndex}
          onChange={changeGauge}
          variant="filled"
          style={{ minWidth: 120 }}
          className={classes.root}
        >
          {generateOptions()}
        </TextField>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <div className="metal-info-wrap" style={styles.metalInfoWrap}>
            <h3 style={styles.metalInfoTitle}>Standard Steel</h3>
            <div style={styles.metalInfoContent}>
              <span>{Standard_inch[currentIndex]} in</span>
              <span>{Standard_mm[currentIndex]} mm</span>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="metal-info-wrap" style={styles.metalInfoWrap}>
            <h3 style={styles.metalInfoTitle}>Galvanized Steel</h3>
            <div style={styles.metalInfoContent}>
              <span>{Galvanized_inch[currentIndex]} in</span>
              <span>{Galvanized_mm[currentIndex]} mm</span>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="metal-info-wrap" style={styles.metalInfoWrap}>
            <h3 style={styles.metalInfoTitle}>Aluminum</h3>
            <div style={styles.metalInfoContent}>
              <span>{Aluminum_inch[currentIndex]} in</span>
              <span>{Aluminum_mm[currentIndex]} mm</span>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default SheetMetalGaugeConversion;
